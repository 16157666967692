import { OptionTypeBase } from 'react-select';

export interface Filters {
  periodType: OptionTypeBase;
  periodValue: OptionTypeBase | Array<Date> | null;
  group: OptionTypeBase;
  name: string;
}

export const getDateRange = (
  periodType: string,
  periodValue: string | Array<Date>
): Array<string> => {
  const currentYear = new Date().getFullYear();
  let strDate: string;

  switch (periodType) {
    case 'month':
      strDate = `${currentYear}-${periodValue}-1`;
      break;
    case 'quarter': {
      const monthQuarter = parseInt(periodValue as string) * 3 - 2;
      strDate = `${currentYear}-${monthQuarter}-1`;
      break;
    }
    case 'year':
      strDate = `${periodValue}-1-1`;
      break;
    case 'custom': {
      const [startDate, endDate] = (periodValue as unknown) as Array<Date>;
      return [startDate.toISOString(), endDate.toISOString()];
    }
    default:
      strDate = `${periodValue}-1-1`;
      break;
  }

  return [new Date(strDate).toISOString()];
};

export const parseOptions = (options: Array<string>) =>
  options.map((label, value) => ({ label, value: String(value + 1) }));

export const parsePlainArray = (options: Array<string>) =>
  options.map(label => ({ label, value: label }));

export const validFilters = (filters: Filters) => {
  const { periodType, periodValue, group } = filters;

  return (
    periodType.value !== null &&
    periodValue &&
    ((periodValue.length &&
      periodValue.every((value: Date) => value !== null)) ||
      (periodValue as OptionTypeBase).value !== null) &&
    group.value !== null
  );
};
