import styled, { css } from 'styled-components';

export interface StyledTooltipWrapperProps {
  visible: boolean;
}

export const TooltipMessage = styled.span`
  box-shadow: 0 1px 6px 0 rgba(79, 79, 79, 0.38);
  background: #ffffff;
  visibility: hidden;
  position: absolute;
  border-radius: 5px;
  text-align: center;
  padding: 6px 10px;
  font-weight: 500;
  font-size: 11px;
  color: #000000;
  display: none;
  bottom: 23px;
  left: 21px;
  z-index: 1;

  &.warning {
    background: rgba(248, 252, 3, 0.6);
  }
`;

export const StyledTooltipWrapper = styled.div<StyledTooltipWrapperProps>`
  position: relative;
  cursor: pointer;

  ${({ visible }) =>
    visible &&
    css`
      ${TooltipMessage} {
        visibility: visible;
        display: block;
      }
    `}
`;
