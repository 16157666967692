import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  email: string;
  firstName: string;
  lastName: string;
  allowPasswordChange: boolean;
  companyId: number;
  confirmed: boolean;
  defaultPassword: string;
  id: number;
  phone?: string;
  role: string;
  testPermissions: boolean;
  userDeleted: boolean;
  photoUrl: string;
  employeeId: number;
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: {}
  },
  reducers: {
    login: (state, { payload }: PayloadAction<UserState>) => {
      const { photoUrl, firstName, lastName } = payload;
      state.currentUser = {
        ...payload,
        photoUrl:
          photoUrl ||
          `https://ui-avatars.com/api/?name=${`${firstName} ${lastName}`}`
      };
    },
    logout: state => {
      state.currentUser = {};
    }
  }
});

export const { login, logout } = userSlice.actions;
export const selectUser = (state: { user: { currentUser: UserState } }) =>
  state.user;
export default userSlice.reducer;
