import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Facebook as LoadingState } from 'react-content-loader';

import AwardsList from './AwardsList/AwardsList';
import AwardsListWeb from './AwardsList/AwardsListWeb';
import SectionWrapper from 'pages/Layout/SectionWrapper';

import { filterAwards } from 'pages/DashboardPage/AwardsSection/helpers';
import { selectUser } from 'redux/slices/userSlice';

import {
  EMPLOYEE_AWARDS,
  EmployeeAwardsData,
  EmployeeAwardsVars
} from 'graphql/queries/employee';

const AwardPage = () => {
  const { t } = useTranslation(['dates', 'awards_section']);

  const { currentUser } = useSelector(selectUser);
  const employeeId = currentUser.employeeId;

  const periodTypes = [
    { label: t('dates:period_type.month'), value: 'month' },
    { label: t('dates:period_type.quarter'), value: 'quarter' },
    { label: t('dates:period_type.year'), value: 'year' }
  ];

  const [periodType, setPeriodType] = useState<OptionTypeBase | null>(null);
  const currentYear = String(new Date().getFullYear());
  const { loading, data } = useQuery<EmployeeAwardsData, EmployeeAwardsVars>(
    EMPLOYEE_AWARDS,
    {
      variables: {
        employeeId,
        year: currentYear
      }
    }
  );

  if (loading) {
    return <LoadingState height="650px" width="100%" />;
  }

  const {
    employee: {
      monthAwards: { nodes: monthAwards },
      quarterAwards: { nodes: quarterAwards },
      yearAwards: { nodes: yearAwards }
    }
  } = data as EmployeeAwardsData;

  const { periodType: selectedPeriodType, awards } = filterAwards(
    {
      monthAwards,
      quarterAwards,
      yearAwards,
      periodType
    },
    periodTypes
  );

  return (
    <SectionWrapper>
      <h1 className="section-title">{t('awards_section:awards')}</h1>
      <AwardsListWeb
        awards={awards}
        periodTypes={periodTypes}
        selectedPeriodType={selectedPeriodType}
        onSelect={option => setPeriodType(option)}
      />
      <AwardsList
        monthAwards={monthAwards}
        quarterAwards={quarterAwards}
        yearAwards={yearAwards}
      />
    </SectionWrapper>
  );
};

export default AwardPage;
