import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';

export const StyledAlert = styled.div`
  position: fixed;
  width: 100%;
  z-index: 3;
  bottom: 0;
  margin-bottom: 0px;
  text-align: center;
  border-radius: 0;
  font-size: 14px;
  border: none;
  padding: 15px;
  background-color: ${Colors.primaryLightGray};

  &.hide-alert {
    display: none;
  }

  &.success {
    background-color: ${Colors.messageSuccess};
  }

  &.error {
    background-color: ${Colors.messageError};
  }

  @media (min-width: ${Breakpoints.medium}) {
    position: absolute;
    bottom: auto;
    top: 80px;
  }

  p {
    font-weight: 600;
    color: ${Colors.normalWhite};
    margin: 0;

    &.close {
      opacity: 1;

      &:hover {
        box-shadow: none;
      }
    }
  }

  a {
    text-decoration: underline;
    font-size: 14px;
    color: ${Colors.normalWhite};
    font-weight: bold;
  }
`;

export const StyledClose = styled.div`
  margin-left: 10px;
  float: right;
  cursor: pointer;

  svg {
    height: 15px;
    width: 15px;
  }
`;
