import styled from 'styled-components';
import { Breakpoints } from 'styles/Constants';

export const RankingScrollContainer = styled.div`
  padding: 0 15px 0 3px;
  overflow-y: auto;
  height: 300px;
  width: 100%;

  @media (min-width: ${Breakpoints.medium}) {
    height: 600px;
  }

  &::-webkit-scrollbar {
    width: 7.5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }
`;
