import { useEffect, MouseEvent, RefObject } from 'react';

type Callback = () => void;

const useOutsideClick = (
  ref: RefObject<HTMLElement | null>,
  callback: Callback
) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener(
      'click',
      (handleClick as unknown) as EventListener
    );

    return () => {
      document.removeEventListener(
        'click',
        (handleClick as unknown) as EventListener
      );
    };
  });
};

export default useOutsideClick;
