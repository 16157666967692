import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { StyledCarousel } from './StyledCarousel';

const defaultProps = {
  disableDotsControls: true,
  infinite: false,
  stagePadding: {
    paddingRight: 100
  }
};

export interface CarouselProps {
  startIndex: number;
  itemsForMobile?: number;
  itemsForWeb?: number;
  itemsForLarge?: number;
  items: Array<JSX.Element>;
  paddingRight?: number;
}

const Carousel: React.FC<CarouselProps> = props => {
  const {
    startIndex,
    items,
    itemsForMobile = 1,
    itemsForWeb = 2,
    itemsForLarge = 3,
    paddingRight
  } = props;

  return (
    <StyledCarousel>
      <AliceCarousel
        mouseTracking
        items={items}
        activeIndex={startIndex}
        paddingRight={paddingRight}
        responsive={{
          0: {
            items: itemsForMobile
          },
          800: {
            items: itemsForWeb
          },
          1650: {
            items: itemsForLarge
          }
        }}
        {...defaultProps}
      />
    </StyledCarousel>
  );
};

export default Carousel;
