import React, { Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useQuery } from '@apollo/client';
import i18n from 'i18next';

import {
  EMPLOYEE_CARD,
  EmployeeCardData,
  EmployeeCardVars
} from 'graphql/queries/employee';
import EmployeeCard from './EmployeeCard';
import { Network } from 'types/appTypes';
import { formatDateTime } from 'helpers/dateHelpers';

interface ConnectedEmployeeCardProps {
  userId: string;
  userPhoto: string;
}

const ConnectedEmployeeCard: React.FC<ConnectedEmployeeCardProps> = props => {
  const { userId, userPhoto } = props;
  const { loading, data } = useQuery<EmployeeCardData, EmployeeCardVars>(
    EMPLOYEE_CARD,
    {
      variables: { userId }
    }
  );

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const {
    user: {
      firstName,
      employee: {
        performance: {
          monthlyRanking: { points, position }
        },
        socialNetworkAccounts: { nodes }
      }
    }
  } = data as EmployeeCardData;

  const networks = nodes.map(({ provider, ...node }) => ({
    ...node,
    network: provider as Network
  }));

  const formatMonth = formatDateTime(i18n.language, 'month');
  const currentMonth = formatMonth(new Date(), 'long');

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <EmployeeCard
        userName={firstName}
        userPhoto={userPhoto}
        networks={networks}
        currentMonth={currentMonth}
        points={points}
        ranking={position}
      />
    </Suspense>
  );
};

export default ConnectedEmployeeCard;
