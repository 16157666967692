import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

export const NavigationLinksWrapper = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0;

  @media (min-width: ${Breakpoints.medium}) {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  a,
  button {
    border-bottom: 1px solid #f5f5f5;
    text-decoration: none;
    position: relative;
    padding: 10px 30px;
    display: block;
    color: inherit;
    z-index: 2;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }

    @media (min-width: ${Breakpoints.medium}) {
      align-items: center;
      border-bottom: none;
      margin: 20px 40px;
      font-weight: 500;
      font-size: 15px;
      display: flex;
      padding: 0;

      &:hover,
      &.active {
        background-color: transparent;
        color: ${Colors.primaryDarkBlue};

        svg {
          fill: ${Colors.primaryDarkBlue};
          stroke: ${Colors.primaryDarkBlue};
        }
      }
    }

    svg {
      display: none;

      @media (min-width: ${Breakpoints.medium}) {
        display: inline;
        margin-right: 5px;
        fill: #757575;
        width: 16px;

        &.terms {
          width: 26px;
        }
      }
    }
  }

  button {
    border: none;
    border-bottom: 1px solid #f5f5f5;
    background: transparent;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    cursor: pointer;
    outline: none;
    width: 100%;
  }

  &.navigation-shadow {
    box-shadow: 0 0px 6px 0 rgba(79, 79, 79, 0.2);·
  }
`;
