import styled from 'styled-components';
import { Colors } from 'styles/Constants';

const StyledLabel = styled.label`
  color: ${Colors.normalBlack};
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  margin: 0;
`;

export default StyledLabel;
