import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

import OnboardingLayout from 'pages/OnboardingLayout/OnboardingLayout';
import LoginPage from 'pages/LoginPage';
import DashboardPage from 'pages/DashboardPage';
import CampaignsPage from 'pages/CampaignsPage/CampaignsPage';
import AwardPage from 'pages/AwardPage/AwardPage';
import FrequentQuestions from 'pages/FrequentQuestions';

import './App.css';

function App() {
  return (
    <Switch>
      <Route exact path="/sign_in">
        <OnboardingLayout className="login-wrapper">
          <LoginPage />
        </OnboardingLayout>
      </Route>

      <PrivateRoute exact path="/dashboard">
        <DashboardPage />
      </PrivateRoute>

      <PrivateRoute exact path="/campaigns">
        <CampaignsPage />
      </PrivateRoute>

      <PrivateRoute exact path="/awards">
        <AwardPage />
      </PrivateRoute>

      <PrivateRoute exact path="/faq">
        <FrequentQuestions />
      </PrivateRoute>

      <Route exact path="/">
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: '/' }
          }}
        />
      </Route>
    </Switch>
  );
}

export default App;
