import styled from 'styled-components';
import { Colors } from 'styles/Constants';

export const RefreshButton = styled.button`
  background: transparent;
  font-size: 1.125rem;
  border-radius: 50%;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  border: none;
  height: 40px;
  width: 40px;

  &:hover {
    box-shadow: 0 5px 12px 0 rgba(44, 62, 80, 0.2);
  }
`;

const CalendarWrapper = styled.div`
  position: relative;

  ${RefreshButton} {
    position: absolute;
    top: 56px;
    left: 234px;
    z-index: 1;

    @media (min-width: 411px) {
      left: 273px;
    }
  }

  .rdrCalendarWrapper {
    display: none;
    box-shadow: 0 4px 32px 0 rgba(204, 204, 204, 0.24),
      0 2px 4px 0 rgba(0, 0, 0, 0.5);
    position: absolute;
    left: -8px;
    z-index: 1;

    @media (min-width: 411px) {
      left: 10px;
    }

    &.open {
      display: inline-flex;
    }

    &.rdrNoSelection {
      .rdrSelected,
      .rdrInRange,
      .rdrStartEdge,
      .rdrEndEdge {
        background: #fff;
      }

      .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
      .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
      .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
      .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
        color: ${Colors.normalBlack};

        &:after {
          background: ${Colors.primaryDarkBlue};
        }
      }
    }

    .rdrMonth {
      @media (max-width: 410px) {
        width: 100%;
      }
    }

    .rdrMonthAndYearPickers {
      text-transform: capitalize;
      color: ${Colors.primaryDarkBlue};
    }

    .rdrWeekDay {
      text-transform: uppercase;
      color: ${Colors.primaryDarkBlue};
      font-weight: 500;
    }

    .rdrMonthPicker,
    .rdrYearPicker {
      select {
        text-transform: capitalize;
        color: ${Colors.primaryDarkBlue};
      }
    }

    .rdrNextButton,
    .rdrPprevButton {
      background: transparent;
      border-radius: 50%;

      &:hover {
        box-shadow: 0 5px 12px 0 rgba(44, 62, 80, 0.2);
      }
    }

    .rdrDay {
      height: 40px;

      .rdrDayNumber {
        height: 100%;
        top: 0;
      }

      .rdrInRange ~ .rdrDayNumber,
      .rdrStartEdge ~ .rdrDayNumber,
      .rdrEndEdge ~ .rdrDayNumber {
        color: ${Colors.normalWhite};
        font-weight: 700;
      }

      .rdrStartEdge,
      .rdrEndEdge {
        color: ${Colors.primaryDarkBlue} !important;
      }

      .rdrStartEdge.rdrEndEdge {
        color: transparent !important;
      }

      .rdrStartEdge.rdrEndEdge ~ .rdrDayNumber,
      .rdrStartEdge.rdrEndEdge ~ .rdrDayNumber:hover {
        background: ${Colors.primaryDarkBlue} !important;
        border-radius: 50%;
      }

      .rdrInRange {
        color: rgba(13, 69, 160, 0.3) !important;
      }

      .rdrDayStartPreview,
      .rdrDayInPreview,
      .rdrDayEndPreview {
        color: transparent !important;
      }

      &.rdrDayDisabled {
        background: ${Colors.normalWhite};
        cursor: default;

        .rdrDayNumber {
          span {
            color: #a5adba !important;
          }
        }
      }
    }

    &:not(.selected) {
      .rdrDay:not(.rdrDayDisabled) {
        &:hover .rdrDayNumber,
        .rdrDayInPreview ~ .rdrDayNumber {
          background: #e6e6e6;
          border-radius: 50%;
        }
      }
    }
  }
`;

export default CalendarWrapper;
