import React from 'react';

import styled from 'styled-components';

import StyledCheckbox from './StyledCheckbox';

export type CheckboxProps = {
  id: string;
  text?: string;
  className?: string;
  checked: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const CheckboxContainer = styled.div`
  margin-bottom: 15px;
  align-items: center;
  display: flex;

  label {
    margin-left: 15px;
    font-size: 13px;
    cursor: pointer;
  }
`;

const Checkbox = (props: CheckboxProps) => {
  const { id, text, className, checked, onClick, ...otherProps } = props;

  return (
    <CheckboxContainer className={className} onClick={onClick}>
      <StyledCheckbox
        id={id}
        checked={checked}
        onChange={() => {}}
        {...otherProps}
      />

      <label className="checkbox-label"></label>
      <label htmlFor={id}>{text}</label>
    </CheckboxContainer>
  );
};

Checkbox.defaultProps = {
  checked: false
};

export default Checkbox;
