import React, { useState, useEffect } from 'react';
import { isEqual } from 'date-fns';

const today = () => {
  const currentDate = new Date();
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
};

const TodayContext = React.createContext<Date>(today());

export const useToday = () => React.useContext(TodayContext);

export const TodayProvider: React.FC = ({ children }) => {
  const [currentDate, setCurrentDate] = useState(today());

  const updateDateIfChanged = () => {
    const newDate = today();

    if (!isEqual(newDate, currentDate)) {
      setCurrentDate(newDate);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(updateDateIfChanged, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <TodayContext.Provider value={currentDate}>
      {children}
    </TodayContext.Provider>
  );
};
