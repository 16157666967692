import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';

export interface AwardsProps {
  hideOnMobile?: boolean;
}

export const AwardCard = styled.div<AwardsProps>`
  ${BaseBox};

  display: grid;
  grid-template-columns: 381px 1fr;
  grid-column-gap: 80px;
  margin-top: 50px;
  box-shadow: none;

  @media (max-width: ${Breakpoints.large}) {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  div.img-container {
    position: relative;
  }

  div.image-label {
    border-top-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  img {
    width: 381px;
    height: 245px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(79, 79, 79, 0.5);

    @media (max-width: ${Breakpoints.large}) {
      width: 100%;
    }
  }

  div.description-text {
    display: block;
    width: 85%;
    overflow-y: auto;

    h2 {
      color: ${Colors.primaryDarkBlue};
      font-size: 30px;
      margin-bottom: 30px;
      font-weight: bold;
    }

    p {
      margin-bottom: 0;
      overflow-y: auto;
      font-size: 14px;
      color: ${Colors.secondaryDarkGrey};
      white-space: pre-line;
      font-weight: 500;
      line-height: 1.5;
    }
  }
`;
