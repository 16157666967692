import { gql } from '@apollo/client';
import { Campaign } from 'types/appTypes';

export const CampaignBasicData = gql`
  fragment CampaignBasicData on Campaign {
    id
    name
    objective
    pictureUrl
    beginDate
    finishedDate
    status
  }
`;

export const CAMPAIGNS_SECTION = gql`
  query GetEmployeeCampaigns($employeeId: ID!, $filters: CampaignSearchInput) {
    employee(id: $employeeId) {
      id
      campaigns(filters: $filters) {
        edges {
          node {
            ...CampaignBasicData
            socialNetworkCampaigns {
              availableProviders
              nodes {
                contentType
                picturesUrls
                provider
                employeePost(employeeId: $employeeId) {
                  actableType
                  status
                }
              }
            }
          }
        }
      }
    }
  }
  ${CampaignBasicData}
`;

export interface CampaignsListData {
  employee: {
    campaigns: {
      pageInfo: {
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        node: Campaign;
      }>;
    };
  };
}

export interface CampaignsListVars {
  employeeId: number;
  filters: {
    status: Array<string>;
  };
  after?: string;
}

export const CAMPAIGNS_LIST = gql`
  query GetEmployeeCampaigns(
    $employeeId: ID!
    $filters: CampaignSearchInput
    $after: String
  ) {
    employee(id: $employeeId) {
      id
      campaigns(filters: $filters, first: 5, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...CampaignBasicData
            socialNetworkCampaigns {
              availableProviders
            }
          }
        }
      }
    }
  }
  ${CampaignBasicData}
`;
