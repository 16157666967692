import React from 'react';
import Alert, { AlertProps } from './Alert';

export type { AlertProps } from './Alert';

export interface DetailedAlertProps extends AlertProps {
  text: string;
}

export const ErrorAlert = (props: DetailedAlertProps) => {
  const { text, ...otherProps } = props;

  return (
    <Alert {...otherProps} type="error">
      <p>{text}</p>
    </Alert>
  );
};

export default Alert;
