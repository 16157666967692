import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import { CancelIcon } from 'components/icons/Catalogue';
import { StyledAlert, StyledClose } from './StyledAlert';

export interface AlertProps {
  type?: string;
  allowClose?: boolean;
  autoClose?: boolean;
  onClose?: () => void;
}

const Alert: React.FC<AlertProps> = props => {
  const [showAlert, setShowAlert] = useState(true);
  const { children, type, allowClose, autoClose, onClose } = props;

  useEffect(() => {
    if (autoClose) {
      const countdown = setTimeout(() => closeAlert(), 5000);
      return () => clearTimeout(countdown);
    }
  });

  const closeAlert = () => {
    setShowAlert(false);
    onClose && onClose();
  };

  if (!showAlert) return null;

  return (
    <StyledAlert className={cx('alert', type)}>
      {allowClose && (
        <StyledClose onClick={closeAlert}>
          <CancelIcon className={'cancel'} color={'#fff'} />
        </StyledClose>
      )}
      {children}
    </StyledAlert>
  );
};

Alert.defaultProps = {
  allowClose: false,
  autoClose: true
};

export default Alert;
