import React from 'react';
import { useTranslation } from 'react-i18next';

import Carousel from 'components/carousel';
import LabelBox from 'components/boxes/LabelBox';
import { StyledAwardsSection } from 'pages/DashboardPage/AwardsSection/Styled';

import { Award } from 'types/appTypes';

import { getStartIndex } from 'pages/DashboardPage/AwardsSection/helpers';

export interface AwardsCarouselProps {
  awards: Array<Award>;
  periodType: string;
  title: string;
}

const AwardsCarousel: React.FC<AwardsCarouselProps> = props => {
  const { awards, title, periodType } = props;
  const { t } = useTranslation(['dates', 'awards_section']);

  if (awards.length === 0) {
    return null;
  }

  const startIndex = getStartIndex(awards, periodType);

  const awardsCards = awards.map((award, index) => {
    const translationPage = t(`dates:${award.periodType}`, {
      returnObjects: true
    });

    const periodValue =
      award.periodType === 'year'
        ? award.periodValue
        : translationPage[parseInt(award.periodValue) - 1];

    const groupName =
      award.group.name === 'All'
        ? t('awards_section:group_all')
        : award.group.name;

    return (
      <LabelBox
        image={award.pictureUrl}
        content={award.description}
        labels={[periodValue, groupName]}
        key={index}
      />
    );
  });

  return (
    <StyledAwardsSection>
      <h2 className="section-title">{title}</h2>
      <Carousel items={awardsCards} startIndex={startIndex} paddingRight={50} />
    </StyledAwardsSection>
  );
};

export default AwardsCarousel;
