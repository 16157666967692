import styled from 'styled-components';
import { Colors } from 'styles/Constants';

const StyledVerticalWrapper = styled.div`
  input {
    border-radius: 6px;
  }

  margin-bottom: 25px;

  label {
    color: ${Colors.primaryDarkBlue};
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 12px;
  }
`;

export default StyledVerticalWrapper;
