import Client from 'config/axios';
import camelcaseKeys from 'camelcase-keys';

export const LogoutRequest = async () => {
  const authHeaders = localStorage.getItem('ironmanAuth');
  const headers = authHeaders ? JSON.parse(authHeaders) : {};
  const { data } = await Client.delete('/auth/sign_out', { headers });
  return {
    data
  };
};

export const Logout = async () => {
  try {
    const { data } = await LogoutRequest();
    localStorage.removeItem('ironmanAuth');

    return { data: camelcaseKeys(data) };
  } catch ({ response }) {
    return { errors: response.data.errors };
  }
};
