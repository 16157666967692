import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface EmployeeData {
  groups: Array<string>;
  hasAwards: boolean;
}

export const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
    groups: [] as string[],
    hasAwards: false
  },
  reducers: {
    updateGroups: (state, { payload }: PayloadAction<EmployeeData>) => {
      state.groups = payload.groups;
      state.hasAwards = payload.hasAwards;
    }
  }
});

export const { updateGroups } = employeeSlice.actions;

export const selectEmployee = (state: { employee: EmployeeData }) =>
  state.employee;

export default employeeSlice.reducer;
