import React from 'react';

import { StyledTag } from './StyledTag';
import Icon from 'components/icons/Icon';

export interface TagProps {
  value?: string;
  variant: string;
  network?: string;
}
const Tag: React.FC<TagProps> = props => {
  const { value, network, ...otherProps } = props;

  return (
    <StyledTag network={network} {...otherProps}>
      {network && <Icon name={network} color={'#000'} />}
      {!network && value}
    </StyledTag>
  );
};

Tag.defaultProps = {
  variant: 'normal'
};

export default Tag;
