import React, { Suspense } from 'react';
import { useQuery } from '@apollo/client';
import { useToday } from 'services/TodayService';
import { useSelector } from 'react-redux';
import { Facebook as LoadingState } from 'react-content-loader';
import InfiniteScroll from 'react-infinite-scroll-component';

import RankingTable from 'components/RankingTable';
import EmptyStateBox from 'components/boxes/EmptyStateBox/EmptyStateBox';
import { RankingScrollContainer } from './Styled';
import RankingFilters, { PlainFilters } from './RankingFilters';
import { getDateRange } from './helpers';
import { selectEmployee } from 'redux/slices/employeeSlice';

import {
  EMPLOYEE_RANKING,
  EmployeeRankingData,
  EmployeeRankingVars
} from 'graphql/queries/employeeRanking';

export interface RankingSectionProps {
  companyId: number;
  employeeId: number;
}

const RankingSection: React.FC<RankingSectionProps> = props => {
  const { employeeId, companyId } = props;
  const today = useToday();

  const { groups } = useSelector(selectEmployee);

  const { loading, data, fetchMore, refetch } = useQuery<
    EmployeeRankingData,
    EmployeeRankingVars
  >(EMPLOYEE_RANKING, {
    variables: {
      companyId,
      filters: {
        periodType: 'month',
        startDate: today.toISOString()
      },
      after: ''
    }
  });

  if (loading) {
    return <LoadingState height="400px" width="100%" />;
  }

  const {
    employeeRanking: {
      edges,
      pageInfo: { hasNextPage, endCursor }
    }
  } = data as EmployeeRankingData;

  const onChangeFilters = (filters: PlainFilters) => {
    const [startDate, endDate] = getDateRange(
      filters.periodType,
      filters.periodValue
    );

    refetch({
      after: '',
      filters: {
        periodType: filters.periodType,
        groupName: filters.groupName,
        name: filters.name,
        startDate,
        endDate
      }
    });
  };

  const fetchNextPage = () =>
    fetchMore({
      variables: {
        after: endCursor
      }
    });

  const employees = edges.map(edge => edge.node);

  return (
    <Suspense fallback={<LoadingState height="400px" width="100%" />}>
      <RankingScrollContainer id="scroll_container">
        <RankingFilters groups={groups} onChange={onChangeFilters} />
        {employees.length > 0 ? (
          <InfiniteScroll
            scrollableTarget="scroll_container"
            dataLength={employees.length}
            hasMore={hasNextPage}
            next={fetchNextPage}
            loader={<h4>Loading...</h4>}
          >
            <RankingTable
              role="employee"
              employees={employees}
              currentEmployeeId={employeeId}
            />
          </InfiniteScroll>
        ) : (
          <EmptyStateBox />
        )}
      </RankingScrollContainer>
    </Suspense>
  );
};

export default RankingSection;
