import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';

const StyledFieldError = styled.span`
  color: ${Colors.inputError};
  display: inline-block;
  padding-left: 10px;
  font-weight: 500;
  font-size: 13px;

  @media (min-width: ${Breakpoints.medium}) {
    grid-column: 2;
  }
`;

export default StyledFieldError;
