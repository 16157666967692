import { es, pt, enUS } from 'date-fns/locale';
import { Locale } from 'date-fns';
import { useTranslation } from 'react-i18next';

export type AvailableLocales = 'es' | 'en' | 'pt';

export const formatDateTime = (locale: string, property: string) => {
  return (date: Date, value: string) =>
    date.toLocaleDateString(locale, { [property]: value });
};

export const chooseLocale = (locale: AvailableLocales): Locale => {
  switch (locale) {
    case 'es':
      return es;
    case 'en':
      return enUS;
    case 'pt':
      return pt;
    default:
      return es;
  }
};

export const useMonthAndDayAbbr = (date: string) => {
  const { t } = useTranslation(['dates']);

  const dateFormat = new Date(date);
  const monthAbbr = t('dates:month_abbreviation', { returnObjects: true });

  return `${monthAbbr[dateFormat.getMonth()]} ${dateFormat.getDate()}`;
};

export const useMonthAndDayRange = (beginDate: string, endDate: string) => {
  const beginDay = useMonthAndDayAbbr(beginDate);
  const endDay = useMonthAndDayAbbr(endDate);

  return `${beginDay} - ${endDay}`;
};
