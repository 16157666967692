import React, { Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  PERFORMANCE_SECTION,
  EmployeePerformanceData,
  EmployeeCardVars
} from 'graphql/queries/employee';

import { GridWrapper, MobileWrapper } from './Styled';
import Icon from 'components/icons/Icon';
import GeneralDataBox from 'components/boxes/GeneralDataBox';
import SingleDataBox from 'components/boxes/SingleDataBox';
import MetricBox from 'components/boxes/MetricBox';
import NetworkParticipationBox from 'components/boxes/NetworkParticipationBox';

interface PerformanceSectionProps {
  userId: string;
}

const PerformanceSection: React.FC<PerformanceSectionProps> = props => {
  const { userId } = props;
  const { loading, data } = useQuery<EmployeePerformanceData, EmployeeCardVars>(
    PERFORMANCE_SECTION,
    {
      variables: { userId }
    }
  );

  const { t } = useTranslation(['performance_section']);

  if (loading) {
    return <LoadingState height="650px" width="100%" />;
  }

  const {
    user: {
      employee: {
        performance: {
          ranking: { points, position },
          postsByNetwork,
          totalPublishedPosts,
          totalParticipatedCampaigns,
          totalInvitedCampaigns,
          potentialReach
        },
        socialNetworkAccounts: { nodes: networks }
      }
    }
  } = data as EmployeePerformanceData;

  const currentYear = new Date().getFullYear();

  const itemsFirstRow = [
    {
      icon: <Icon name="trophy" size="big" />,
      value: position.toString(),
      text: t('performance_section:ranking_position', { currentYear })
    },
    {
      icon: <Icon name="star" size="big" />,
      value: points.toString(),
      text: t('performance_section:points')
    },
    {
      icon: <Icon name="report" size="big" />,
      value: totalPublishedPosts.toString(),
      text: t('performance_section:generated_content')
    }
  ];

  const networksBoxItems = networks.map(({ linked, provider }) => ({
    linked,
    network: provider,
    percentage: postsByNetwork[provider].toString()
  }));

  return (
    <Suspense fallback={<LoadingState height="650px" width="100%" />}>
      <GeneralDataBox items={itemsFirstRow} />

      <GridWrapper className="hide-on-mobile">
        <SingleDataBox>
          <MetricBox
            iconName="total-reach"
            value={potentialReach.toString()}
            text={t('performance_section:potential_reach')}
          />
        </SingleDataBox>

        <SingleDataBox>
          <NetworkParticipationBox
            onConnect={() => alert('Maybe some other time')}
            items={networksBoxItems}
          />
        </SingleDataBox>

        <SingleDataBox>
          <MetricBox
            iconName="flag"
            value={`${totalParticipatedCampaigns}/${totalInvitedCampaigns}`}
            text={t('performance_section:campaigns_participation')}
          />
        </SingleDataBox>
      </GridWrapper>

      <MobileWrapper className="show-on-mobile">
        <MetricBox
          iconName="total-reach"
          value={potentialReach.toString()}
          text={t('performance_section:potential_reach')}
          topText={true}
        />
        <NetworkParticipationBox
          onConnect={() => alert('Maybe some other time')}
          items={networksBoxItems}
          topText={true}
        />
        <MetricBox
          iconName="flag"
          value={`${totalParticipatedCampaigns}/${totalInvitedCampaigns}`}
          text={t('performance_section:campaigns_participation')}
          topText={true}
        />
      </MobileWrapper>
    </Suspense>
  );
};

export default PerformanceSection;
