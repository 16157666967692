import React, { useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { OptionTypeBase } from 'react-select';

import {
  EMPLOYEE_AWARDS,
  EmployeeAwardsData,
  EmployeeAwardsVars
} from 'graphql/queries/employee';

import { StyledAwardsSection } from './Styled';
import LabelBox from 'components/boxes/LabelBox';
import Carousel from 'components/carousel';
import EmptyStateBox from 'components/boxes/EmptyStateBox/EmptyStateBox';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import { getStartIndex, filterAwards } from './helpers';

export interface AwardsSectionProps {
  employeeId: number;
}

const AwardsSection: React.FC<AwardsSectionProps> = props => {
  const { t } = useTranslation(['dates', 'awards_section']);

  const periodTypes = [
    { label: t('dates:period_type.month'), value: 'month' },
    { label: t('dates:period_type.quarter'), value: 'quarter' },
    { label: t('dates:period_type.year'), value: 'year' }
  ];

  const [periodType, setPeriodType] = useState<OptionTypeBase | null>(null);
  const currentYear = String(new Date().getFullYear());
  const { employeeId } = props;
  const { loading, data } = useQuery<EmployeeAwardsData, EmployeeAwardsVars>(
    EMPLOYEE_AWARDS,
    {
      variables: {
        employeeId,
        year: currentYear
      }
    }
  );

  if (loading) {
    return <LoadingState height="650px" width="100%" />;
  }

  const {
    employee: {
      monthAwards: { nodes: monthAwards },
      quarterAwards: { nodes: quarterAwards },
      yearAwards: { nodes: yearAwards }
    }
  } = data as EmployeeAwardsData;

  const { periodType: selectedPeriodType, awards } = filterAwards(
    {
      monthAwards,
      quarterAwards,
      yearAwards,
      periodType
    },
    periodTypes
  );

  const awardsCards = awards.map((award, index) => {
    const translationPage = t(`dates:${award.periodType}`, {
      returnObjects: true
    });

    const periodValue =
      award.periodType === 'year'
        ? award.periodValue
        : translationPage[parseInt(award.periodValue) - 1];

    const groupName =
      award.group.name === 'All'
        ? t('awards_section:group_all')
        : award.group.name;

    return (
      <LabelBox
        image={award.pictureUrl}
        content={award.description}
        labels={[periodValue, groupName]}
        key={index}
      />
    );
  });

  const startIndex = getStartIndex(
    awards,
    (selectedPeriodType as OptionTypeBase).value
  );

  return (
    <StyledAwardsSection>
      <DropdownSelect
        onSelect={option => setPeriodType(option)}
        value={selectedPeriodType}
        defaultValue={periodTypes[0]}
        options={periodTypes}
      />
      {awards.length > 0 ? (
        <Carousel
          items={awardsCards}
          startIndex={startIndex}
          paddingRight={50}
        />
      ) : (
        <EmptyStateBox
          title={t('awards_section:sorry_title')}
          description={t('awards_section:empty_state_description')}
        />
      )}
    </StyledAwardsSection>
  );
};

export default AwardsSection;
