import styled from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

export const HamburguerMenu = styled.div`
  height: auto;

  @media (max-width: ${Breakpoints.medium}) {
    padding: 24px 30px 22px 0;
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
  }

  svg {
    fill: ${Colors.normalBlack};
    height: 24px;
    width: 24px;
  }
`;

export const ToogleBox = styled.div`
  @media (min-width: ${Breakpoints.medium}) {
    display: none;
  }
`;
