import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { Colors } from 'styles/Constants';

import Icon from 'components/icons/Icon';

interface StyledNavigationLinkProps extends NavLinkProps {
  direction: 'back' | 'forward';
}

export interface NavigationLinkProps extends StyledNavigationLinkProps {
  text: string;
}

const StyledNavigationLink = styled(NavLink)<StyledNavigationLinkProps>`
  color: ${Colors.primaryDarkBlue};
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 25px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  display: flex;

  svg {
    fill: ${Colors.primaryDarkBlue};
    height: 16px;
    width: 16px;
  }

  &:focus,
  &:hover {
    color: ${Colors.primaryDarkBlue};
    text-decoration: none;
  }

  @media (min-width: $breakpoint-medium) {
    max-width: 150px;
    margin-bottom: 45px;
  }

  ${({ direction }) =>
    direction === 'forward' &&
    css`
      justify-content: flex-end;
    `}
`;

const NavigationLink = ({ text, direction, to }: NavigationLinkProps) => {
  const linkProps = { direction, to };

  if (direction === 'back') {
    return (
      <StyledNavigationLink {...linkProps}>
        <Icon name="arrow-back" />
        {text}
      </StyledNavigationLink>
    );
  }

  return (
    <StyledNavigationLink {...linkProps}>
      {text}
      <Icon name="arrow-forward" />
    </StyledNavigationLink>
  );
};

export default NavigationLink;
