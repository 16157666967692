import styled, { css } from 'styled-components';
import { Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';

export interface StyledSingleDataBoxProps {
  withoutBorder?: boolean;
}

export const StyledSingleDataBox = styled.div<StyledSingleDataBoxProps>`
  ${BaseBox};

  align-items: center;
  display: grid;
  padding: 15px;

  @media (min-width: ${Breakpoints.medium}) {
    padding: 35px 15px;
  }

  ${({ withoutBorder }) =>
    withoutBorder &&
    css`
      box-shadow: none;
      padding: 0px;
      background-color: transparent;

      @media (min-width: ${Breakpoints.medium}) {
        display: flex;
      }
    `}
`;
