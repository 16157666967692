import styled from 'styled-components';
import BaseInput from 'components/inputs/BaseInput';

import Search from 'images/icons/search.svg';
import SearchBlue from 'images/icons/search-blue.svg';

const StyledInput = styled(BaseInput)`
  height: 40px;

  &[type='search'] {
    background-image: url(${Search});
    background-repeat: no-repeat;
    background-position: 12px 50%;
    background-size: 20px;
    padding: 5px 15px 5px 40px;
    cursor: pointer;
    height: 32px;

    &:focus {
      background-image: url(${SearchBlue});
      border: 1px solid $primary-dark-blue;
    }

    &:hover {
      background-image: url(${SearchBlue});
    }
  }
}
`;

export default StyledInput;
