import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import LoginForm, { userSchema } from './LoginForm';
import { ErrorAlert } from 'components/Alert';
import { Login, LoginData } from 'services/rest/authentication';
import { login } from 'redux/slices/userSlice';

interface Location {
  from: {
    pathname: string;
  };
}

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<Location>();
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<LoginData>({
    resolver: yupResolver(userSchema)
  });

  const [backendError, setBackendError] = useState<string | null>(null);

  const onSubmit = async ({ email, password }: LoginData) => {
    const { user, errors } = await Login({ email, password });

    if (user) {
      dispatch(login(user));
      const { from } = location.state || { from: { pathname: '/' } };
      history.replace(from);
    }

    if (errors) {
      setBackendError(errors[0]);
    }
  };

  return (
    <>
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          autoClose={false}
          onClose={() => setBackendError(null)}
        />
      )}

      <LoginForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
      />
    </>
  );
};

export default LoginPage;
