import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionTypeBase } from 'react-select';
import { throttle } from 'throttle-debounce';

import { FiltersForm } from './Styled';
import Input from 'components/inputs/Input';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import Calendar from 'components/Calendar';
import {
  Filters,
  parseOptions,
  parsePlainArray,
  validFilters
} from '../helpers';

export interface PlainFilters {
  periodType: string;
  periodValue: string;
  groupName: string;
  name: string;
}

export interface RankingFiltersProps {
  groups: Array<string>;
  onChange: (filters: PlainFilters) => void;
  currentDate?: Date;
}

const RankingFilters: React.FC<RankingFiltersProps> = props => {
  const { groups, onChange, currentDate = new Date() } = props;
  const { t } = useTranslation(['dates', 'ranking_filters']);

  const periodTypeOptions = [
    { label: t('ranking_filters:month'), value: 'month' },
    { label: t('ranking_filters:quarter'), value: 'quarter' },
    { label: t('ranking_filters:year'), value: 'year' },
    { label: t('ranking_filters:custom'), value: 'custom' }
  ];

  const months = parseOptions(
    t('dates:month', { returnObjects: true, defaultValue: [] }) as []
  );
  const groupsOptions = parsePlainArray(groups);

  const [periodValueOptions, setPeriodValueOptions] = useState(months);

  const [filters, setFilters] = useState<Filters>({
    periodType: periodTypeOptions[0],
    periodValue: periodValueOptions[currentDate.getMonth()],
    group: groupsOptions[0],
    name: ''
  });

  const onChangeFilters = (newFilters: Filters) => {
    const periodValue = Array.isArray(newFilters.periodValue)
      ? newFilters.periodValue
      : (newFilters.periodValue as OptionTypeBase).value;

    if (validFilters(newFilters)) {
      onChange({
        periodValue,
        periodType: newFilters.periodType.value,
        groupName: newFilters.group.value,
        name: newFilters.name
      });
    }
  };

  const throttledOnChange = useCallback(
    throttle(250, false, (newFilters: Filters) => onChangeFilters(newFilters)),
    []
  );

  const onChangeFilter = (newFilters: Filters) => {
    setFilters(newFilters);
    throttledOnChange(newFilters);
  };

  const onChangePeriodType = (option: OptionTypeBase) => {
    let options;

    switch (option.value) {
      case 'month':
        options = months;
        break;
      case 'quarter':
        options = parseOptions(t('dates:quarter', { returnObjects: true }));
        break;
      case 'year':
        options = parsePlainArray(['2017', '2018', '2019', '2020', '2021']);
        break;
      default:
        options = months;
    }

    setPeriodValueOptions(options);
    setFilters({
      ...filters,
      periodType: option,
      periodValue: null
    });
  };

  const onSelectCustomFilter = (startDate: Date, endDate: Date) => {
    const newFilters = { ...filters, periodValue: [startDate, endDate] };
    onChangeFilter(newFilters);
  };

  return (
    <FiltersForm>
      <Input
        placeholder={t('ranking_filters:search_employees')}
        type="search"
        value={filters.name}
        onChange={evt => onChangeFilter({ ...filters, name: evt.target.value })}
      />
      <span className="filter-by-label">{t('ranking_filters:filter_by')}</span>
      <DropdownSelect
        options={periodTypeOptions}
        value={filters.periodType}
        onSelect={onChangePeriodType}
      />

      {filters.periodType.value === 'custom' ? (
        <Calendar onChange={onSelectCustomFilter} />
      ) : (
        <DropdownSelect
          options={periodValueOptions}
          value={filters.periodValue}
          onSelect={periodValue => onChangeFilter({ ...filters, periodValue })}
        />
      )}

      <DropdownSelect
        options={groupsOptions}
        value={filters.group}
        onSelect={group => onChangeFilter({ ...filters, group })}
      />
    </FiltersForm>
  );
};

export default RankingFilters;
