import styled, { css } from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';

export interface StyledHorizontalBoxProps {
  size?: string;
  backgroundImage?: string;
  fallbackBackground?: string;
  hoverable?: boolean;
  backgroundBox?: string;
}

export const StyledHorizontalBox = styled.div<StyledHorizontalBoxProps>`
  ${BaseBox};

  display: grid;

  @media (min-width: ${Breakpoints.large}) {
    grid-template-columns: 30% 1fr;
  }

  ${({ hoverable }) =>
    hoverable === true &&
    css`
      &:focus,
      &:hover {
        box-shadow: 0px 4px 10px 0 ${Colors.primaryDarkBlue};
      }
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      @media (min-width: ${Breakpoints.large}) {
        grid-template-columns: 200px 1fr;
      }
    `}

  ${({ backgroundBox }) =>
    backgroundBox === 'blue-gradient' &&
    css`
      background-image: linear-gradient(215deg, #3e65fe, #3ebbfe);
      text-align: left;
      box-shadow: none;
      color: #fff;
    `}
`;

export const HeaderHorizontalBox = styled.div<StyledHorizontalBoxProps>`
  position: relative;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  min-height: 150px;

  @media (min-width: ${Breakpoints.medium}) {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
  }

  p {
    font-size: 13px;
    padding: 20px;
    font-weight: 500;
    line-height: 1.5;
    color: #333333;
  }

  img {
    margin: 15px 30px;
    padding: 10px;
    height: 100px;
    width: 100px;

    @media (min-width: ${Breakpoints.medium}) {
      margin: 15px auto;
      height: 142px;
      width: 142px;
    }
  }

  ${({ backgroundImage, fallbackBackground }) =>
    (backgroundImage || fallbackBackground) &&
    css`
      background-image: url(${backgroundImage}), url(${fallbackBackground});

      p {
        color: ${Colors.normalWhite};
      }
    `}
`;
