import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SectionWrapper from 'pages/Layout/SectionWrapper';
import ConnectedEmployeeCard from './EmployeeCard';
import CampaignsSection from './CampaignsSection';
import PerformanceSection from './PerformanceSection';
import AwardsSection from './AwardsSection';
import RankingSection from './RankingSection';

import { selectUser } from 'redux/slices/userSlice';
import { selectEmployee } from 'redux/slices/employeeSlice';

const DashboardPage = () => {
  const { currentUser } = useSelector(selectUser);
  const { hasAwards } = useSelector(selectEmployee);
  const { t } = useTranslation(['employee_dashboard']);

  const userId = currentUser.id.toString();
  const employeeId = currentUser.employeeId;

  return (
    <>
      <ConnectedEmployeeCard userId={userId} userPhoto={currentUser.photoUrl} />
      <CampaignsSection employeeId={employeeId} />

      <SectionWrapper className="dots-wrapper">
        <h1 className="section-title">{t('employee_dashboard:profile')}</h1>
        <PerformanceSection userId={userId} />
      </SectionWrapper>

      {hasAwards && (
        <SectionWrapper>
          <h1 className="section-title">{t('employee_dashboard:awards')}</h1>
          <AwardsSection employeeId={employeeId} />
        </SectionWrapper>
      )}

      <SectionWrapper className="blue-wrapper">
        <h1 className="section-title">{t('employee_dashboard:ranking')}</h1>
        <RankingSection
          employeeId={employeeId}
          companyId={currentUser.companyId}
        />
      </SectionWrapper>
    </>
  );
};

export default DashboardPage;
