import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from './StyledCampaignsSection';
import { Campaign } from 'types/appTypes';

import VerticalBox from 'components/boxes/VerticalBox';
import Tag from 'components/Tag/Tag';
import Button from 'components/buttons/Button';
import WithoutImage from 'images/backgrounds/campaign_without_image.png';

import { useMonthAndDayRange } from 'helpers/dateHelpers';

const isFutureDate = (date: string) => {
  return new Date(date) > new Date();
};

const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
  evt.currentTarget.src = WithoutImage;
};

const CallToAction: React.FC<Campaign> = props => {
  const { socialNetworkCampaigns, beginDate } = props;

  const { t } = useTranslation(['campaign_section']);

  if (isFutureDate(beginDate)) {
    return <Button value={t('campaign_section:coming_soon')} disabled={true} />;
  }

  const isPublishedCampaign = socialNetworkCampaigns.nodes.every(
    snc => snc.employeePost.status === 'published'
  );

  return (
    <Button
      value={
        isPublishedCampaign
          ? t('campaign_section:see_results')
          : t('campaign_section:participate')
      }
      variant="primary"
    />
  );
};

const CampaignCard: React.FC<Campaign> = props => {
  const {
    pictureUrl,
    beginDate,
    finishedDate,
    name,
    objective,
    socialNetworkCampaigns: { availableProviders }
  } = props;

  const dateRange = useMonthAndDayRange(beginDate, finishedDate);

  return (
    <Card>
      <VerticalBox
        header={
          <div>
            <Tag value={dateRange} variant="normal" />
            <img
              onError={evt => addDefaultPicture(evt)}
              src={pictureUrl || WithoutImage}
            />
          </div>
        }
        networks={availableProviders}
        disabled={isFutureDate(beginDate)}
      >
        <h2>{name}</h2>
        <p>{objective}</p>
        <CallToAction {...props} />
      </VerticalBox>
    </Card>
  );
};

export default CampaignCard;
