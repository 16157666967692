import styled from 'styled-components';
import { Breakpoints } from 'styles/Constants';

const CampaignsListContainer = styled.div`
  overflow-y: auto;
  padding: 5px;
  max-height: 600px;

  .infinite-scroll-component {
    padding: 1px;

    @media (min-width: ${Breakpoints.medium}) {
      padding: 3px 3px 0 0;
    }
  }
`;

export default CampaignsListContainer;
