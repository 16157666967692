import React from 'react';

import {
  StyledHorizontalBox,
  HeaderHorizontalBox
} from './StyledHorizontalBox';

export interface HorizontalBoxProps {
  header?: JSX.Element;
  hoverable?: boolean;
  size?: string;
  backgroundImage?: string;
  fallbackBackground?: string;
  backgroundBox?: string;
}

const HorizontalBox: React.FC<HorizontalBoxProps> = props => {
  const {
    header,
    hoverable,
    size,
    backgroundImage,
    fallbackBackground,
    backgroundBox,
    children
  } = props;

  return (
    <StyledHorizontalBox
      size={size}
      hoverable={hoverable}
      backgroundBox={backgroundBox}
    >
      <HeaderHorizontalBox
        backgroundImage={backgroundImage}
        fallbackBackground={fallbackBackground}
      >
        {header}
      </HeaderHorizontalBox>

      {children}
    </StyledHorizontalBox>
  );
};

export default HorizontalBox;
