import React from 'react';

import DescriptionBox from 'components/boxes/DescriptionBox';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { useTranslation } from 'react-i18next';

const FrequentQuestions = () => {
  const { t } = useTranslation(['faq']);
  const questions = t('faq:questions', {
    returnObjects: true,
    defaultValue: []
  }) as [];

  return (
    <SectionWrapper>
      <h1 className="section-title">{t('faq:title')}</h1>
      {questions.map((element, index) => {
        return (
          <DescriptionBox
            title={element['question']}
            textDescription={element['answer']}
            toggle={true}
            key={index}
          />
        );
      })}
    </SectionWrapper>
  );
};

export default FrequentQuestions;
