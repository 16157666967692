import React from 'react';
import { useTranslation } from 'react-i18next';
import { OptionTypeBase } from 'react-select';

import { AwardCard } from 'pages/AwardPage/StyledAwardPage';
import Tag from 'components/Tag/Tag';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import EmptyStateBox from 'components/boxes/EmptyStateBox/EmptyStateBox';
import { Award } from 'types/appTypes';

export interface AwardsListWebProps {
  awards: Array<Award>;
  periodTypes: Array<OptionTypeBase>;
  selectedPeriodType: OptionTypeBase | null;
  onSelect: (value: OptionTypeBase) => void;
}

const AwardsListWeb: React.FC<AwardsListWebProps> = props => {
  const { awards, periodTypes, selectedPeriodType, onSelect } = props;
  const { t } = useTranslation(['dates', 'awards_section']);

  if (awards.length === 0) {
    return (
      <EmptyStateBox
        title={t('awards_section:title')}
        description={t('awards_section:description')}
      />
    );
  }

  return (
    <div className="hide-on-mobile">
      <DropdownSelect
        onSelect={onSelect}
        value={selectedPeriodType}
        defaultValue={periodTypes[0]}
        options={periodTypes}
      />
      {awards.map((award, index) => {
        const translationPage = t(`dates:${award.periodType}`, {
          returnObjects: true
        });

        const periodValue =
          award.periodType === 'year'
            ? award.periodValue
            : translationPage[parseInt(award.periodValue) - 1];

        const groupName =
          award.group.name === 'All'
            ? t('awards_section:group_all')
            : award.group.name;

        return (
          <AwardCard key={index}>
            <div className="img-container">
              <Tag value={groupName} variant="normal" />
              <img className="image-label" src={award.pictureUrl} />
            </div>
            <div className="description-text">
              <h2>{periodValue}</h2>
              <p>{award.description}</p>
            </div>
          </AwardCard>
        );
      })}
    </div>
  );
};

export default AwardsListWeb;
