import styled from 'styled-components';
import { Colors } from 'styles/Constants';

export const StyledDescription = styled.div`
  padding: 20px;

  h2 {
    font-weight: bold;
    position: relative;
    font-size: 18px;
  }

  p {
    margin: 0 0 5px 0;
    font-weight: 500;
    line-height: 1.5;
    font-size: 14px;
  }
`;

export const ToggleBox = styled.div`
  border-radius: 10px;
  box-shadow: 0 1px 6px 0 rgba(79, 79, 79, 0.38);
  margin-bottom: 20px;
  padding: 20px;

  .head-box {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  h2 {
    font-weight: bold;
    position: relative;
    font-size: 1rem;
    margin: 0px;
    color: ${Colors.secondaryNormalGrey};

    &.active {
      color: ${Colors.primaryDarkBlue};
    }
  }

  p {
    border-top: 1px solid #e2e3e5;
    padding-top: 15px;
    margin-top: 15px;

    font-weight: 500;
    line-height: 1.8;
    font-size: 14px;
    display: none;
    white-space: pre-wrap;

    &.show-description {
      display: block;
    }
  }

  svg.rotate-icon {
    transform: rotate(180deg);
  }
`;
