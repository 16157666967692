import React from 'react';
import { useTranslation } from 'react-i18next';

import AwardsCarousel from './AwardsCarousel';
import EmptyStateBox from 'components/boxes/EmptyStateBox/EmptyStateBox';
import { Award } from 'types/appTypes';

export interface AwardsListProps {
  monthAwards: Array<Award>;
  quarterAwards: Array<Award>;
  yearAwards: Array<Award>;
}

const AwardsList: React.FC<AwardsListProps> = props => {
  const { monthAwards, quarterAwards, yearAwards } = props;
  const { t } = useTranslation(['dates', 'awards_section']);

  if (
    monthAwards.length === 0 &&
    quarterAwards.length === 0 &&
    yearAwards.length === 0
  ) {
    return (
      <EmptyStateBox
        title={t('awards_section:sorry_title')}
        description={t('awards_section:empty_state_description')}
      />
    );
  }

  return (
    <div className="show-on-mobile">
      <AwardsCarousel
        periodType="month"
        title={t('dates:period_type.month')}
        awards={monthAwards}
      />
      <AwardsCarousel
        periodType="quarter"
        title={t('dates:period_type.quarter')}
        awards={quarterAwards}
      />
      <AwardsCarousel
        periodType="year"
        title={t('dates:period_type.year')}
        awards={yearAwards}
      />
    </div>
  );
};

export default AwardsList;
