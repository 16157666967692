import React from 'react';
import VerticalWrapper, { VerticalWrapperProps } from './VerticalWrapper';
import Input, { DefaultProps, InputProps } from 'components/inputs/Input';

export interface InputWrapperProps extends VerticalWrapperProps, InputProps {}

export const InputWrapper = React.forwardRef<DefaultProps, InputWrapperProps>(
  ({ label, ...otherProps }, ref) => (
    <VerticalWrapper label={label}>
      <Input {...otherProps} ref={ref} />
    </VerticalWrapper>
  )
);

InputWrapper.displayName = 'InputWrapper';
