import React from 'react';

import { StyledFooter } from './StyledFooter';
import Icon from 'components/icons/Icon';

interface BrowserDetail {
  name: string;
  version: string;
}

export interface FooterProps {
  browsers: Array<BrowserDetail>;
}

const Footer: React.FC<FooterProps> = props => {
  const { browsers } = props;

  return (
    <StyledFooter>
      {browsers.map(({ name, version }, index) => (
        <li key={index}>
          <Icon name={name} color={'#bababa'} />
          <span>{version}</span>
        </li>
      ))}
    </StyledFooter>
  );
};

export default Footer;
