import styled, { css } from 'styled-components';
import { Breakpoints } from 'styles/Constants';

interface BodyWrapperProps {
  showLinks?: boolean;
  showHeader?: boolean;
}

const BodyWrapper = styled.div<BodyWrapperProps>`
  margin-top: 100px;

  @media (min-width: ${Breakpoints.large}) {
    margin-top: 140px;
  }

  ${({ showLinks, showHeader }) =>
    (showLinks || showHeader) &&
    css`
      @media (min-width: ${Breakpoints.medium}) {
        margin-top: 180px;
      }
    `}

  ${({ showHeader }) =>
    showHeader &&
    css`
      @media (max-width: ${Breakpoints.medium}) {
        margin-top: 0px;
      }
    `}
`;

export default BodyWrapper;
