import styled from 'styled-components';

export const Card = styled.div`
  margin: 0 10px 10px;
`;

export const EmptyCard = styled.div`
  margin: 0 10px 10px;
  width: 40%;
`;
