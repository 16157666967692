import React from 'react';
import { StyledLabelBox, HeaderLabelBox, BodyLabelBox } from './Styled';

export interface LabelBoxProps {
  image: string;
  labels: Array<string>;
  content: string;
}

const LabelBox: React.FC<LabelBoxProps> = props => {
  const { image, labels, content } = props;
  return (
    <StyledLabelBox>
      <HeaderLabelBox>
        <img src={image} />
        <div className="tags">
          {labels.map((label, index) => (
            <span key={index}>{label}</span>
          ))}
        </div>
      </HeaderLabelBox>
      <BodyLabelBox>
        <p>{content}</p>
      </BodyLabelBox>
    </StyledLabelBox>
  );
};

export default LabelBox;
