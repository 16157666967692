import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SectionWrapper from 'pages/Layout/SectionWrapper';
import CampaignsList from './CampaignsList';
import NavigationLink from 'components/links/NavigationLink';

import { selectUser } from 'redux/slices/userSlice';

const CampaignsPage = () => {
  const { currentUser } = useSelector(selectUser);
  const { t } = useTranslation(['campaigns_page']);

  const employeeId = currentUser.employeeId;

  return (
    <>
      <SectionWrapper>
        <NavigationLink text={t('back')} direction="back" to="/dashboard" />

        <h1 className="section-title">{t('campaigns')}</h1>

        <h2 className="section-title">{t('active')}</h2>
        <CampaignsList
          id="active_campaigns"
          employeeId={employeeId}
          status="ACTIVATED"
        />
      </SectionWrapper>

      <SectionWrapper>
        <h2 className="section-title">{t('completed')}</h2>
        <CampaignsList
          id="completed_campaigns"
          employeeId={employeeId}
          status="COMPLETED"
        />
      </SectionWrapper>
    </>
  );
};

export default CampaignsPage;
