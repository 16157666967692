import React from 'react';

import { Colors } from 'styles/Constants';
import RoundIcon, { RoundIconProps } from './RoundIcon';
import SquareIcon from './SquareIcon';
import Icon from './Icon';

export type SocialMediaIconProps = RoundIconProps & {
  shape: 'round' | 'square' | 'regular';
  variant: 'dark' | 'ligth' | 'disabled';
};

const SocialMediaIcon = (props: SocialMediaIconProps) => {
  const { variant, shape, name, ...otherProps } = props;

  let color, background: string;

  switch (variant) {
    case 'dark':
      color = Colors.normalWhite;
      background = Colors[name];
      break;
    case 'ligth':
      color = Colors[name];
      background = Colors.normalWhite;
      break;
    case 'disabled':
      color = Colors.secondaryDarkBlue;
      background = '#e0e0e0';
      break;
  }

  if (shape === 'round') {
    return (
      <RoundIcon
        {...otherProps}
        name={name}
        color={color}
        background={background}
      />
    );
  }

  if (shape === 'square') {
    return (
      <SquareIcon
        {...otherProps}
        name={name}
        color={color}
        background={background}
      />
    );
  }

  return <Icon {...otherProps} name={name} color={name} />;
};

SocialMediaIcon.defaultProps = {
  size: 'medium',
  variant: 'dark',
  shape: 'round'
};

export default SocialMediaIcon;
