import React, { useState } from 'react';
import cx from 'classnames';

import { useTransparency } from 'components/NavBar/AnimatedNavBar';
import { HamburguerMenu, ToogleBox } from './StyledMobileLinks';

import NavigationLinks, {
  NavigationLinksProps
} from 'components/NavBar/NavigationLinks';

import Icon from 'components/icons/Icon';

const MobileLinks: React.FC<NavigationLinksProps> = props => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { className, links } = props;
  const toogleTransparency = useTransparency();

  const onClick = () => {
    setMenuOpen(!menuOpen);
    toogleTransparency();
  };

  return (
    <ToogleBox className={cx({ 'menu-open': menuOpen })}>
      <HamburguerMenu onClick={onClick}>
        {menuOpen && <Icon name="cancel" />}
        {!menuOpen && <Icon name="bars" />}
      </HamburguerMenu>

      {menuOpen && <NavigationLinks className={className} links={links} />}
    </ToogleBox>
  );
};

export default MobileLinks;
