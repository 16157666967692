import React from 'react';
import Footer from './Footer';

export type { FooterProps } from './Footer';

export const BrowsersFooter = () => (
  <Footer
    browsers={[
      {
        name: 'microsoft-edge',
        version: '81+'
      },
      {
        name: 'chrome',
        version: '81+'
      },
      {
        name: 'firefox',
        version: '78+'
      },
      {
        name: 'safari',
        version: '13+'
      }
    ]}
  />
);

export default Footer;
