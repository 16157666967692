import { Award } from 'types/appTypes';
import { OptionTypeBase } from 'react-select';

// Logic to find the index to place the carousel

export const getStartIndex = (awards: Array<Award>, periodType: string) => {
  const currentPeriod = selectCurrentPeriod(periodType);
  return availablePeriodValue(awards, currentPeriod);
};

const selectCurrentPeriod = (periodType: string) => {
  const currentTime = new Date();

  switch (periodType) {
    case 'quarter':
      return String(currentTime.getMonth() / 3 + 1);
    case 'year':
      return String(currentTime.getFullYear());
    default:
      return String(currentTime.getMonth() + 1);
  }
};

const availablePeriodValue = (awards: Array<Award>, currentPeriod: string) =>
  awards
    .map(award => award.periodValue)
    .findIndex(periodValue => periodValue >= currentPeriod);

// Logic to select which awards show in Dashboard

export interface AwardsTypeOptions {
  monthAwards: Array<Award>;
  quarterAwards: Array<Award>;
  yearAwards: Array<Award>;
  periodType: OptionTypeBase | null;
}

export const filterAwards = (
  awardsTypeOptions: AwardsTypeOptions,
  periodTypes: Array<OptionTypeBase>
) => {
  if (awardsTypeOptions.periodType) {
    return periodTypePresent(awardsTypeOptions);
  }
  return initialPeriodType(awardsTypeOptions, periodTypes);
};

const periodTypePresent = (awardsTypeOptions: AwardsTypeOptions) => {
  const {
    monthAwards,
    quarterAwards,
    yearAwards,
    periodType
  } = awardsTypeOptions;

  switch ((periodType as OptionTypeBase).value) {
    case 'month':
      return { periodType, awards: monthAwards };
    case 'quarter':
      return { periodType, awards: quarterAwards };
    case 'year':
      return { periodType, awards: yearAwards };
    default:
      return { periodType, awards: [] };
  }
};

const initialPeriodType = (
  awardsTypeOptions: AwardsTypeOptions,
  periodTypes: Array<OptionTypeBase>
) => {
  const { monthAwards, quarterAwards, yearAwards } = awardsTypeOptions;

  switch (true) {
    case monthAwards.length > 0:
      return { periodType: periodTypes[0], awards: monthAwards };
    case quarterAwards.length > 0:
      return { periodType: periodTypes[1], awards: quarterAwards };
    case yearAwards.length > 0:
      return { periodType: periodTypes[2], awards: yearAwards };
    default:
      return { periodType: periodTypes[0], awards: [] };
  }
};
