import React from 'react';
import {
  StyledSingleDataBox,
  StyledSingleDataBoxProps
} from './StyledSingleDataBox';

export type SingleDataBoxProps = StyledSingleDataBoxProps;

const SingleDataBox: React.FC<SingleDataBoxProps> = props => {
  const { children, withoutBorder } = props;
  return (
    <StyledSingleDataBox withoutBorder={withoutBorder}>
      {children}
    </StyledSingleDataBox>
  );
};

export default SingleDataBox;
