import React from 'react';
import { Colors } from 'styles/Constants';
import Button from 'components/buttons/Button';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'components/progress_bar/ProgressBar';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';

import StyledNetworkParticipationBox, {
  StyledButtonCollection
} from './StyledNetworkParticipationBox';

export interface NetworkProps {
  network: string;
  percentage: string;
  linked: boolean;
}

export interface NetworkParticipationBoxProps {
  items: Array<NetworkProps>;
  onConnect: (network: string) => void;
  topText?: boolean;
}

const NetworkParticipationBox: React.FC<NetworkParticipationBoxProps> = props => {
  const { t } = useTranslation(['network_participation']);
  const { items, onConnect, topText } = props;
  const unLinkedNetworks = items.filter(item => item.linked === false);

  return (
    <StyledNetworkParticipationBox {...props}>
      {topText && (
        <h3>{t('network_participation:contents_by_social_network')}</h3>
      )}

      {items.map(({ network, percentage }, index) => (
        <ProgressBar
          key={index}
          withNumber={true}
          color={Colors[network]}
          percentage={percentage}
          icon={<SocialMediaIcon name={network} size="small" />}
        />
      ))}

      {!topText && (
        <h3>{t('network_participation:contents_by_social_network')}</h3>
      )}

      <StyledButtonCollection className="button-collection">
        {unLinkedNetworks.map(({ network }, index) => (
          <Button
            key={index}
            size="small"
            icon={network}
            value={t('network_participation:link_account')}
            variant={network}
            onClick={() => onConnect(network)}
          />
        ))}
      </StyledButtonCollection>
    </StyledNetworkParticipationBox>
  );
};

export default NetworkParticipationBox;
