const Breakpoints = {
  small: '481px',
  medium: '641px',
  xmedium: '878px',
  large: '1025px',
  xLarge: '1200px',
  xxLarge: '1441px',
  xxxLarge: '1921px'
};

const PrimaryColors = {
  primaryNormalBlue: '#3EABFE',
  primaryNormalPurple: '#b721ff',
  primaryDarkBlue: '#004fea',
  primaryLightGray: '#979797'
};

const SecondaryColors = {
  secondaryNormalYellow: '#FEEA3E',
  secondaryNormalRed: '#ff6266',
  secondaryNormalPink: '#d113fe',
  secondaryLightBlue: '#f1f8ff',
  secondaryLightGray: '#f5f6f8',
  secondaryDarkGrey: '#4a4a4a',
  secondaryDarkPink: '#bd10e0',
  secondaryDarkBlue: '#42526e',
  secondaryNormalGrey: '#4d4d4d'
};

const MessageColors = {
  messageInfo: '#3ea8fe',
  messageError: '#ec5b54',
  messageSuccess: '#7cba53',
  inputError: '#ff303f',

  normalBlack: '#4D4D4D',
  normalWhite: '#FFFFFF',
  normalGreyLight: '#F4F4F4'
};

const SocialMediaColors = {
  facebook: '#3B5998',
  twitter: '#1DA1F2',
  linkedin: '#0077b5',
  instagram: '#df3a7b'
};

const IconColors = {
  success: '#7ed321',
  alert: '#ff991f',
  danger: '#d62d2d',
  inactive: '#a5adba'
};

const RankingColors = {
  gold: '#f8e71c',
  silver: '#ffb953',
  bronze: '#757575'
};

const Colors: { [key: string]: string } = {
  ...PrimaryColors,
  ...SecondaryColors,
  ...MessageColors,
  ...SocialMediaColors,
  ...IconColors,
  ...RankingColors
};

export { Breakpoints, Colors };
