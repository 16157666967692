import React from 'react';
import Icon from 'components/icons/Icon';
import { useConfirmation } from 'services/ConfirmationService';
import { Colors } from 'styles/Constants';
import {
  Answers,
  ConfirmationOptions
} from 'components/boxes/confirmation_box/ConfirmationBox';

import {
  DropdownContentList,
  DropdownContentItem,
  DropdownContainer,
  DropdownElement
} from './StyledDropdown';

export interface DropdownItemProps {
  text: string;
  onClick: (answers?: Answers) => void;
  confirmation?: ConfirmationOptions;
}

export interface DropdownProps {
  items: Array<DropdownItemProps>;
  className?: string;
}

const DropdownOption: React.FC<DropdownItemProps> = props => {
  const confirm = useConfirmation();
  const { text, onClick, confirmation } = props;

  let onClickOption: (...args: any[]) => any;

  if (confirmation) {
    onClickOption = () => {
      confirm(confirmation)
        .then(onClick)
        .catch(() => {});
    };
  } else {
    onClickOption = onClick;
  }

  return (
    <DropdownContentItem>
      <button onClick={() => onClickOption()}>{text}</button>
    </DropdownContentItem>
  );
};

const Dropdown: React.FC<DropdownProps> = props => {
  const { items, children, className } = props;

  return (
    <DropdownContainer className={className}>
      <DropdownElement>
        {children}
        <Icon name="arrow-dropdown" color={Colors.normalBlack} />
      </DropdownElement>

      <DropdownContentList>
        {items.map((item, index) => (
          <DropdownOption key={index} {...item} />
        ))}
      </DropdownContentList>
    </DropdownContainer>
  );
};

export default Dropdown;
