import styled from 'styled-components';

import { Colors } from 'styles/Constants';

import image from 'images/icons/check.svg';

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  visibility: hidden;
  margin: 0;

  + label.checkbox-label {
    border: 1px solid ${Colors.primaryLightGray};
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    user-select: none;
    cursor: pointer;
    display: block;
    outline: none;
    height: 15px;
    width: 15px;
  }

  + label.checkbox-label:before,
  + label.checkbox-label:after {
    position: absolute;
    display: block;
    content: '';
  }

  + label.checkbox-label:before {
    transition-property: background;
    transition-duration: 0.3s;
    bottom: 0px;
    right: 0px;
    left: 0px;
    top: 0px;
  }

  &:checked + label.checkbox-label {
    background-image: url(${image});
    background-color: ${Colors.primaryDarkBlue};
    border: 1px solid ${Colors.primaryDarkBlue};
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export default StyledCheckbox;
