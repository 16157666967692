import React from 'react';
import styled from 'styled-components';

import { Colors } from 'styles/Constants';
import Icon, { IconProps } from './Icon';

export type PossibleStatus = 'success' | 'alert' | 'danger' | 'inactive';

export type StatusIconProps = IconProps & {
  variant: PossibleStatus;
};

const IconNames: { [key: string]: string } = {
  success: 'check-rounded',
  alert: 'alert-rounded',
  danger: 'close-rounded'
};

const StatusIcon = (props: StatusIconProps) => {
  const { variant, ...otherProps } = props;

  const color = Colors[variant];
  const iconName = IconNames[variant];

  return <Icon {...otherProps} name={iconName} color={color} />;
};

StatusIcon.defaultProps = {
  size: 'small',
  name: ''
};

export default styled(StatusIcon)``;
