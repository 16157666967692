import React from 'react';

import StyledButton, { StyledButtonProps } from './StyledButton';
import Icon from 'components/icons/Icon';

export interface ButtonProps extends StyledButtonProps {
  value: string;
  onClick?: () => void;
  className?: string;
}

const Button = (buttonProps: ButtonProps) => {
  const { value, icon, className, ...otherProps } = buttonProps;

  return (
    <StyledButton className={className} icon={icon} {...otherProps}>
      {icon && <Icon name={icon} />}
      {value}
    </StyledButton>
  );
};

Button.defaultProps = {
  variant: 'default',
  size: 'regular',
  disabled: false
};

export default Button;
