import styled from 'styled-components';
import { Colors } from 'styles/Constants';

export const StyledFooter = styled.ul`
  display: inline-flex;
  justify-content: center;
  text-align: center;
  color: ${Colors.normalBlack};
  background: ${Colors.normalWhite};
  padding: 10px 0px;
  width: 100%;
  font-size: 0.6875rem;
  margin: 0;

  li {
    align-items: center;
    margin-right: 20px;
    list-style: none;
    display: flex;

    &:last-child {
      margin: 0;
    }
  }

  svg {
    vertical-align: middle;
  }

  span {
    margin-left: 5px;
    font-weight: bold;
    color: #bababa;
  }
`;
