import React, { FC, HTMLAttributes } from 'react';
import StyledLabel from './StyledLabel';

interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
  text: string;
}

const Label: FC<LabelProps> = ({ text, ...props }) => (
  <StyledLabel {...props}>{text}</StyledLabel>
);

export default Label;
