import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';

const backend =
  process.env.NODE_ENV === 'test' ? require('i18next-fs-backend') : Backend;

const initOptions = () => {
  if (process.env.NODE_ENV === 'test') {
    return {
      initImmediate: false,
      debug: false,
      lng: 'es',
      backend: {
        loadPath: 'public/locales/{{lng}}/{{ns}}.json'
      },
      fallbackLng: 'es',
      whitelist: ['es', 'en', 'pt'],
      interpolation: {
        escapeValue: false
      },
      react: {
        useSuspense: false
      }
    };
  }

  return {
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {
          prefix: 'i18next_res_',
          expirationTime: 7 * 24 * 60 * 60 * 1000,
          defaultVersion: '',
          versions: {},
          store: window.localStorage
        }
      ]
    },
    debug: false,
    lng: 'es',
    fallbackLng: 'es',
    whitelist: ['es', 'en', 'pt'],
    preload: ['es'],
    interpolation: {
      escapeValue: false
    }
  };
};

i18n.use(backend).use(initReactI18next).init(initOptions());

export default i18n;
