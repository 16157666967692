import React, { InputHTMLAttributes, DetailedHTMLProps } from 'react';
import cx from 'classnames';

import StyledInput from './StyledInput';
import FieldError from 'components/inputs/FieldError';

export type DefaultProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  HTMLInputElement;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type: string;
  error?: string;
}

const Input = React.forwardRef<DefaultProps, InputProps>(
  ({ type, error, ...otherProps }, ref) => (
    <>
      <StyledInput
        ref={ref}
        type={type}
        className={cx({ 'field-error': Boolean(error) })}
        {...otherProps}
      />

      {error && <FieldError text={error} />}
    </>
  )
);

Input.defaultProps = {
  type: 'text'
};

Input.displayName = 'Input';

export default Input;
