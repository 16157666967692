import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Instagram as LoadingState } from 'react-content-loader';

import { EmptyCard } from './StyledCampaignsSection';

import CampaignCard from './CampaignCard';
import Carousel from 'components/carousel/Carousel';
import VerticalBox from 'components/boxes/VerticalBox';
import EmptyCampaign from 'images/empty_states/empty_campaigns.png';
import NavigationLink from 'components/links/NavigationLink';
import SectionWrapper from 'pages/Layout/SectionWrapper';

import {
  CAMPAIGNS_SECTION,
  CampaignsListData,
  CampaignsListVars
} from 'graphql/queries/employeeCampaigns';

import { Campaign } from 'types/appTypes';

interface CampaignsSectionProps {
  employeeId: number;
}

const CampaignsSection: React.FC<CampaignsSectionProps> = props => {
  const { t } = useTranslation(['campaign_section']);

  const { employeeId } = props;

  const { loading, data } = useQuery<CampaignsListData, CampaignsListVars>(
    CAMPAIGNS_SECTION,
    {
      variables: {
        employeeId,
        filters: {
          status: ['ACTIVATED', 'PENDING']
        }
      }
    }
  );

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const {
    employee: {
      campaigns: { edges }
    }
  } = data as CampaignsListData;

  const employeeCampaigns = edges.map(edge => edge.node);

  const isFutureDate = (campaign: Campaign) => {
    return new Date(campaign.beginDate) > new Date();
  };

  const orderByDate = (allCampaigns: Array<Campaign>) => {
    return allCampaigns.sort((a, b) => {
      return (
        new Date(a.beginDate).getTime() - new Date(b.beginDate).getTime() ||
        new Date(a.finishedDate).getTime() - new Date(b.finishedDate).getTime()
      );
    });
  };

  const orderByAction = (allCampaigns: Array<Campaign>) => {
    const campaigns = allCampaigns.slice();
    const participatedCampaigns = campaigns.filter(campaign => {
      return campaign.socialNetworkCampaigns.nodes.every(
        snc => snc.employeePost.status === 'published'
      );
    });

    const nextCampaigns = campaigns.filter(campaign => {
      return isFutureDate(campaign);
    });

    const actionCampaigns = campaigns.filter(campaign => {
      return (
        !participatedCampaigns.includes(campaign) &&
        !nextCampaigns.includes(campaign)
      );
    });

    return actionCampaigns.concat(participatedCampaigns, nextCampaigns);
  };

  const orderCampaigns = () => {
    const allCampaigns = employeeCampaigns.slice();
    const campaignsByDate = orderByDate(allCampaigns);
    const orderedCampaigns = orderByAction(campaignsByDate);
    return orderedCampaigns;
  };

  const campaignCards = () => {
    if (employeeCampaigns.length > 0) {
      return orderCampaigns().map((campaign: Campaign, index: number) => (
        <CampaignCard {...campaign} key={index} />
      ));
    }

    return [
      <EmptyCard key={1}>
        <VerticalBox header={<img src={EmptyCampaign} />}>
          <h2>{t('campaign_section:empty_state_subtitle')}</h2>
          <p>{t('campaign_section:empty_state_description')}</p>
        </VerticalBox>
      </EmptyCard>
    ];
  };

  return (
    <SectionWrapper>
      <h1 className="section-title">
        {employeeCampaigns.length > 0
          ? t('campaign_section:active_campaigns')
          : t('campaign_section:empty_state_title')}
      </h1>
      <Carousel
        items={campaignCards()}
        startIndex={0}
        itemsForMobile={1.5}
        itemsForWeb={3}
      />
      <NavigationLink
        text={t('campaign_section:see_all')}
        direction={'forward'}
        to={'/campaigns'}
      />
    </SectionWrapper>
  );
};

export default CampaignsSection;
