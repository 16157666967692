import styled from 'styled-components';
import { StyledDescription } from 'components/boxes/DescriptionBox/StyledDescriptionBox';

export const CampaignCardWrapper = styled.div`
  padding: 20px;

  ${StyledDescription} {
    padding: 0 25px 0 0;
  }

  button {
    width: 90%;
  }

  p {
    font-weight: 500;
    line-height: 1.5;
    font-size: 14px;

    &.purple-text {
      color: #b721ff;
    }
  }
`;

export const NetworksCard = styled.div`
  align-items: center;
  display: flex;
  margin: 15px 0;

  > * {
    margin-right: 10px;
  }
`;
