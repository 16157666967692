export const getCurrentCompany = () => {
  const subdomain = window.location.hostname.split('.');

  if (subdomain[0] === 'localhost') return 'fluvip';

  return subdomain[0] || 'fluvip';
};

export const getCurrentCompanyBackground = () => {
  const subdomain = getCurrentCompany();

  return `${process.env.REACT_APP_COMPANIES_RESOURCES_URL}/backgrounds/background_${subdomain}.jpg`;
};

export const getCurrentCompanyLogo = () => {
  const subdomain = getCurrentCompany();

  return `${process.env.REACT_APP_COMPANIES_RESOURCES_URL}/logos/${subdomain}.svg`;
};
