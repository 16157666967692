import styled from 'styled-components';
import BaseBox from 'components/boxes/BaseBox';

export const StyledConfirmationMessage = styled.span`
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  display: block;
`;

export const ConfirmationOptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

export const StyledConfirmationOption = styled.button`
  text-transform: uppercase;
  background: transparent;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  color: #001fee;
  outline: none;
  border: none;
`;

export const ConfirmationBoxWrapper = styled.div`
  ${BaseBox};

  border-radius: 4px;
  padding: 24px 20px 20px;
  width: 280px;
  margin: 0;
  right: 0;

  label {
    font-weight: 300;
    font-size: 13px;
  }
`;
