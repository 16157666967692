import Client from 'config/apollo';
import {
  EmployeeGroupsData,
  EmployeeGroupsVars,
  EMPLOYEE_GROUPS
} from 'graphql/queries/employee';

export const fetchEmployeeData = async (employeeId: number) => {
  const {
    data: { employee }
  } = await Client.query<EmployeeGroupsData, EmployeeGroupsVars>({
    query: EMPLOYEE_GROUPS,
    variables: { employeeId }
  });

  const groups = employee.groups.nodes.map(group => group.name);

  return { groups, hasAwards: employee.shouldRenderAwards };
};
