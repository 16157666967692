import { gql } from '@apollo/client';
import { SocialNetworkAccounts, Awards } from 'types/appTypes';

interface Ranking {
  points: number;
  position: number;
}

// Employee Card
export interface EmployeeCardData {
  user: {
    firstName: string;
    lastName: string;
    employee: {
      performance: {
        monthlyRanking: Ranking;
      };
      socialNetworkAccounts: SocialNetworkAccounts;
    };
  };
}

export interface EmployeeCardVars {
  userId: string;
}

export const EmployeeSocialNetworkAccounts = gql`
  fragment EmployeeSocialNetworkAccounts on Employee {
    socialNetworkAccounts {
      nodes {
        id
        linked
        invalidToken
        provider
      }
    }
  }
`;

export const EMPLOYEE_CARD = gql`
  query GetEmployeeProfile($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      employee {
        id
        performance {
          monthlyRanking {
            points
            position
          }
        }
        ...EmployeeSocialNetworkAccounts
      }
    }
  }
  ${EmployeeSocialNetworkAccounts}
`;

// Employee Performance
export interface EmployeePerformanceData {
  user: {
    employee: {
      performance: {
        ranking: Ranking;
        postsByNetwork: {
          facebook: number;
          twitter: number;
          linkedin: number;
          instagram: number;
          [key: string]: number;
        };
        totalPublishedPosts: number;
        totalParticipatedCampaigns: number;
        totalInvitedCampaigns: number;
        potentialReach: number;
      };
      socialNetworkAccounts: SocialNetworkAccounts;
    };
  };
}

export const PERFORMANCE_SECTION = gql`
  query GetEmployeeProfile($userId: ID!) {
    user(id: $userId) {
      id
      employee {
        id
        performance {
          ranking {
            points
            position
          }
          postsByNetwork {
            facebook
            twitter
            linkedin
            instagram
          }
          totalPublishedPosts
          totalParticipatedCampaigns
          totalInvitedCampaigns
          potentialReach
        }
        ...EmployeeSocialNetworkAccounts
      }
    }
  }
  ${EmployeeSocialNetworkAccounts}
`;

// Awards Section
export interface EmployeeAwardsData {
  employee: {
    monthAwards: Awards;
    quarterAwards: Awards;
    yearAwards: Awards;
  };
}

export interface EmployeeAwardsVars {
  employeeId: number;
  year: string;
}

export const EMPLOYEE_AWARDS = gql`
  query EmployeeAwards($employeeId: ID!, $year: String!) {
    employee(id: $employeeId) {
      monthAwards: awards(filters: { periodType: "month", year: $year }) {
        nodes {
          id
          periodType
          periodValue
          pictureUrl
          description
          group {
            name
          }
        }
      }
      quarterAwards: awards(filters: { periodType: "quarter", year: $year }) {
        nodes {
          id
          periodType
          periodValue
          pictureUrl
          description
          group {
            name
          }
        }
      }
      yearAwards: awards(filters: { periodType: "year" }) {
        nodes {
          id
          periodType
          periodValue
          pictureUrl
          description
          group {
            name
          }
        }
      }
    }
  }
`;

// Groups
export interface EmployeeGroupsVars {
  employeeId: number;
}

export interface EmployeeGroupsData {
  employee: {
    shouldRenderAwards: boolean;
    groups: {
      nodes: Array<{
        name: string;
      }>;
    };
  };
}

export const EMPLOYEE_GROUPS = gql`
  query EmployeeGroups($employeeId: ID!) {
    employee(id: $employeeId) {
      shouldRenderAwards
      groups {
        nodes {
          name
        }
      }
    }
  }
`;
