import * as React from 'react';

import ConfirmationBox, {
  ConfirmationOptions,
  Answers
} from 'components/boxes/confirmation_box/ConfirmationBox';

const ConfirmationServiceContext = React.createContext<
  (options: ConfirmationOptions) => Promise<Answers | undefined>
>(Promise.reject);

export const useConfirmation = () =>
  React.useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider: React.FC = ({ children }) => {
  const [
    confirmationState,
    setConfirmationState
  ] = React.useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: (answers?: Answers) => void;
    reject: () => void;
  }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);

    return new Promise<Answers | undefined>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = (answers?: Answers) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(answers);
    }

    setConfirmationState(null);
  };

  const confirmationProps = confirmationState || { confirmationMessage: '' };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>

      <ConfirmationBox
        isOpen={Boolean(confirmationState)}
        onConfirm={handleSubmit}
        onCancel={handleClose}
        {...confirmationProps}
      />
    </>
  );
};
