import styled from 'styled-components';
import { Breakpoints } from 'styles/Constants';

import { ToogleBox } from './MobileLinks/StyledMobileLinks';

export const NavBarHeader = styled.div`
  justify-content: space-between;
  background: #f2f2f2;
  align-items: center;
  position: relative;
  padding: 0 30px;
  display: flex;
  width: 100%;
  z-index: 2;

  @media (min-width: ${Breakpoints.medium}) {
    padding: 0 130px;
  }
`;

export const NavBarLogo = styled.div`
  padding: 20px 0;

  img {
    max-width: 160px;

    &.dark-logo {
      display: block;
    }
    &.light-logo {
      display: none;
    }

    @media (min-width: ${Breakpoints.medium}) {
      max-width: 200px;
    }
  }
`;

export const NavBarLinks = styled.div`
  padding-right: 35px;
  align-items: center;
  display: flex;

  @media (min-width: ${Breakpoints.medium}) {
    padding: 0;
  }

  span {
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    display: none;

    @media (min-width: ${Breakpoints.medium}) {
      display: block;
    }
  }

  img.user-photo {
    border-radius: 100%;
    height: 35px;
    width: 35px;

    @media (min-width: ${Breakpoints.medium}) {
      margin-right: 10px;
    }
  }

  div.desktop-links {
    @media (max-width: ${Breakpoints.medium}) {
      display: none;
    }
  }
`;

export const NavBarWrapper = styled.div`
  transition: all 0.5s ease-out 0s;
  background: #fff;
  position: fixed;
  z-index: 1030;
  width: 100%;
  right: 0;
  left: 0;
  top: 0;

  &.with-transparency.transparent {
    @media (max-width: ${Breakpoints.medium}) {
      background-color: transparent;
      background: transparent;

      ${NavBarHeader} {
        background: transparent;
      }

      img.dark-logo {
        display: none;
      }

      img.light-logo {
        display: block;
      }

      img.user-photo {
        display: none;
      }

      ${ToogleBox} {
        svg {
          fill: #fff;
        }

        &.menu-open {
          ul.mobile-navigation {
            background: #fff;
          }
        }
      }
    }
  }

  ul.desktop-navigation {
    @media (max-width: ${Breakpoints.medium}) {
      display: none;
    }
  }
`;
