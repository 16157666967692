import React from 'react';
import cx from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { Optional } from 'types/utilTypes';

import { NavigationLinksWrapper } from './StyledNavigationLinks';
import Icon from 'components/icons/Icon';

type ClickEvent = () => void;

interface LinkProps extends NavLinkProps {
  text: string;
  icon?: string;
  active?: boolean;
  onClick?: ClickEvent;
}

export type NavigationLinkProps = Optional<LinkProps, 'to'>;

export interface NavigationLinksProps {
  links: Array<NavigationLinkProps>;
  className?: string;
  id?: string;
}

const NavigationLinkItem: React.FC<NavigationLinkProps> = props => {
  const { text, to, icon, active, onClick } = props;

  if (to) {
    return (
      <li>
        <NavLink to={to as LocationDescriptor} className={cx({ active })}>
          {icon && <Icon name={icon} />}
          {text}
        </NavLink>
      </li>
    );
  }

  if (onClick) {
    return (
      <li>
        <button onClick={onClick}>{text}</button>
      </li>
    );
  }

  return null;
};

const NavigationLinks: React.FC<NavigationLinksProps> = props => {
  const { links, id, className } = props;

  return (
    <NavigationLinksWrapper id={id} className={className}>
      {links.map((item, index) => (
        <NavigationLinkItem key={index} {...item} />
      ))}
    </NavigationLinksWrapper>
  );
};

export default NavigationLinks;
