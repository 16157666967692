import React from 'react';
import { useTranslation } from 'react-i18next';

import { SocialMediaDropdown } from 'components/dropdowns';
import { SocialMediaIcon, IconWithStatus } from 'components/icons/Catalogue';
import { Network } from 'types/appTypes';
import Button from 'components/buttons/Button';

export interface NetworkProps {
  linked: boolean;
  invalidToken: boolean;
  network: Network;
}

export interface NetworksListProps {
  networks: Array<NetworkProps>;
}

const getActionsForNetwork = (invalidToken: boolean) => {
  const { t } = useTranslation(['networks_list']);

  if (invalidToken) {
    return [
      {
        text: t('networks_list:update'),
        onClick: () => alert('It was about time!')
      }
    ];
  } else {
    return [
      {
        text: t('networks_list:unlink'),
        onClick: () => alert('How could you do this to me?')
      }
    ];
  }
};

const NetworksList = (props: NetworksListProps) => {
  const { networks } = props;
  const { t } = useTranslation(['networks_list']);

  return (
    <>
      {networks.map((networkItem, index) => {
        const { linked, invalidToken, network, ...otherProps } = networkItem;

        if (linked) {
          const actions = getActionsForNetwork(invalidToken);
          const status = invalidToken ? 'alert' : 'success';

          return (
            <SocialMediaDropdown
              key={index}
              network={network}
              items={actions}
              status={status}
              {...otherProps}
            />
          );
        } else {
          return (
            <React.Fragment key={index}>
              <Button
                className="hide-on-mobile"
                variant={network}
                size="small"
                icon={network}
                value={t('networks_list:connect')}
                onClick={() => alert('I will work, I promise!')}
              />
              <IconWithStatus
                onClick={() => alert('Bravo!')}
                className="show-on-mobile connect-account"
                status="danger"
              >
                <SocialMediaIcon
                  size="medium"
                  variant="disabled"
                  shape="square"
                  name={network}
                />
              </IconWithStatus>
            </React.Fragment>
          );
        }
      })}
    </>
  );
};

export default NetworksList;
