import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BodyWrapper from './StyledLayout';
import { AnimatedNavBar } from 'components/NavBar';
import { updateGroups } from 'redux/slices/employeeSlice';
import { Logout } from 'services/rest/logout';
import { logout } from 'redux/slices/userSlice';
import { selectUser } from 'redux/slices/userSlice';
import { fetchEmployeeData } from './helpers';

interface LayoutProps {
  pathname: string;
}

const Layout: React.FC<LayoutProps> = props => {
  const { pathname, children } = props;
  const { currentUser } = useSelector(selectUser);
  const { t } = useTranslation(['layout']);
  const [hasAwards, setHasAwards] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const employeeId = currentUser.employeeId;

  useEffect(() => {
    fetchEmployeeData(employeeId).then(employee => {
      setHasAwards(employee.hasAwards);
      dispatch(updateGroups(employee));
    });
  }, [employeeId]);

  const actions = [
    {
      text: t('layout:sign_out'),
      onClick: async () => {
        const { data } = await Logout();
        if (data.success) {
          history.replace({ pathname: '/' });
          dispatch(logout());
        }
      }
    }
  ];

  const links = [
    {
      to: '/dashboard',
      icon: 'dashboard',
      text: t('layout:dashboard')
    },
    {
      to: '/faq',
      icon: 'question',
      text: t('layout:faq')
    },
    {
      to: '/legal',
      icon: 'terms',
      text: t('layout:terms')
    }
  ];

  if (hasAwards) {
    links.splice(1, 0, {
      to: '/awards',
      icon: 'awards',
      text: t('layout:awards')
    });
  }

  const navigationLinks = links.map(link => ({
    ...link,
    active: link.to === pathname
  }));

  const fullName = `${currentUser.firstName} ${currentUser.lastName}`;

  const isDashboard = pathname === '/dashboard';

  return (
    <>
      <AnimatedNavBar
        blueNavbar={isDashboard}
        navigationShadow={true}
        customScroll={30}
        customWidth={640}
        actions={actions}
        links={navigationLinks}
        userName={fullName}
        userPhoto={currentUser.photoUrl}
      />
      <BodyWrapper showHeader={isDashboard} showLinks={true}>
        {children}
      </BodyWrapper>
    </>
  );
};

export default Layout;
