import { gql } from '@apollo/client';

export interface EmployeeRankingItem {
  badPractices: boolean;
  employeeId: number;
  fullName: string;
  position: number;
  points: number;
  photo: string;
}

export interface EmployeeRankingData {
  employeeRanking: {
    edges: Array<{
      node: EmployeeRankingItem;
    }>;
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
  };
}

export interface EmployeeRankingVars {
  companyId: number;
  filters: {
    periodType: string;
    groupName?: string;
    startDate: string;
    endDate?: string;
    name?: string;
  };
  after: string;
}

export const EMPLOYEE_RANKING = gql`
  query EmployeeRanking(
    $companyId: ID!
    $filters: RankingSearchInput
    $after: String
  ) {
    employeeRanking(
      companyId: $companyId
      filters: $filters
      after: $after
      first: 10
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          badPractices
          employeeId
          position
          fullName
          points
          photo
        }
      }
    }
  }
`;
