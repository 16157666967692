import React from 'react';
import styled from 'styled-components';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const ThreeDotsContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThreeDots: React.FC<IContentLoaderProps> = props => (
  <ThreeDotsContainer>
    <ContentLoader
      viewBox="0 0 400 160"
      height={160}
      width="100%"
      backgroundColor="transparent"
      {...props}
    >
      <circle cx="150" cy="86" r="8" />
      <circle cx="194" cy="86" r="8" />
      <circle cx="238" cy="86" r="8" />
    </ContentLoader>
  </ThreeDotsContainer>
);

export default ThreeDots;
