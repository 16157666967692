import React from 'react';
import {
  OnboardingWrapper,
  OnboardingMobileBackground,
  FormWrapper
} from './Styled';

import {
  getCurrentCompanyBackground,
  getCurrentCompany
} from 'helpers/companyHelper';

export interface OnboardingLayoutProps {
  className?: string;
}

const OnboardingLayout: React.FC<OnboardingLayoutProps> = props => {
  const { className, children } = props;
  const brandBackground = getCurrentCompanyBackground();
  const subdomain = getCurrentCompany();

  return (
    <>
      <OnboardingMobileBackground
        brandBackground={brandBackground}
        className="onboarding-layout-mobile"
        id={subdomain}
      ></OnboardingMobileBackground>

      <OnboardingWrapper
        brandBackground={brandBackground}
        className="onboarding-layout"
        id={subdomain}
      >
        <FormWrapper className={className}>{children}</FormWrapper>
      </OnboardingWrapper>
    </>
  );
};

export default OnboardingLayout;
