import React from 'react';
import { useTranslation } from 'react-i18next';

import { SocialMediaIcon } from 'components/icons/Catalogue';
import HorizontalBox from 'components/boxes/HorizontalBox';
import { StyledDescription } from 'components/boxes/DescriptionBox/StyledDescriptionBox';
import Button from 'components/buttons/Button';
import { Campaign } from 'types/appTypes';
import { useMonthAndDayRange } from 'helpers/dateHelpers';

import { CampaignCardWrapper, NetworksCard } from './Styled';

import CampaignAvatar from 'images/backgrounds/campaign_without_image.png';

const actionVariants: Record<string, string> = {
  COMPLETED: 'quaternary',
  ACTIVATED: 'tertiary'
};

const actionColor: Record<string, string> = {
  COMPLETED: '#42526e',
  ACTIVATED: '#ffffff'
};

const CampaignCard: React.FC<Campaign> = props => {
  const {
    pictureUrl,
    beginDate,
    finishedDate,
    status,
    name,
    objective,
    socialNetworkCampaigns: { availableProviders }
  } = props;

  const { t } = useTranslation(['campaign_card']);

  const dateRange = useMonthAndDayRange(beginDate, finishedDate);

  const actionText =
    status === 'COMPLETED'
      ? t('campaign_card:see_results')
      : t('campaign_card:participate');

  return (
    <HorizontalBox
      backgroundImage={pictureUrl}
      fallbackBackground={CampaignAvatar}
      size="small"
    >
      <CampaignCardWrapper className="grid-block-center">
        <StyledDescription className="span-sm-12 span-md-8">
          <p className="purple-text">{dateRange}</p>
          <h2>{name}</h2>
          <p>{objective}</p>
        </StyledDescription>

        <div className="span-sm-12 span-md-4">
          <NetworksCard>
            <p>{t('campaign_card:active_networks')}</p>
            {availableProviders.map((provider, index) => (
              <SocialMediaIcon
                key={index}
                size="medium"
                variant="ligth"
                shape="round"
                name={provider}
              />
            ))}
          </NetworksCard>
          <Button
            color={actionColor[status]}
            variant={actionVariants[status]}
            value={actionText}
          />
        </div>
      </CampaignCardWrapper>
    </HorizontalBox>
  );
};

export default CampaignCard;
