import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormMethods, FieldErrors } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';

import { LoginData } from 'services/rest/authentication';

import { StyledForm, PoweredByWrapper } from './Styled';
import { InputWrapper } from 'components/inputs/VerticalWrapper';
import Button from 'components/buttons/Button';
import { BrowsersFooter } from 'components/Footer';
import AdvocatesProLogo from 'images/logos/advocates_logo.svg';

import { getCurrentCompanyLogo } from 'helpers/companyHelper';
import { RequiredString } from 'helpers/yupHelper';

export interface LoginFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<LoginData>;
  register: UseFormMethods['register'];
}

export const userSchema = yup.object().shape({
  email: RequiredString().email('email'),
  password: RequiredString().min(8, 'login_form.password_length')
});

const LoginForm: React.FC<LoginFormProps> = props => {
  const { errors, onSubmit, register } = props;
  const { t } = useTranslation(['login_form']);

  const companyLogo = getCurrentCompanyLogo();

  return (
    <>
      <NavLink to="/" className="logo">
        <img className="hide-on-mobile" src={companyLogo} alt="Home" />
        <img
          className="show-on-mobile mobile-logo"
          src={companyLogo}
          alt="Home"
        />
      </NavLink>

      <StyledForm onSubmit={onSubmit}>
        <InputWrapper
          label={t('login_form:email')}
          name="email"
          ref={register}
          type="email"
          error={errors.email?.message}
          data-testid="email"
        />

        <InputWrapper
          label={t('login_form:password')}
          name="password"
          type="password"
          ref={register}
          error={errors.password?.message}
          data-testid="password"
        />

        <NavLink to="#nowhere" className="forgot-password">
          {t('login_form:forgot_password')}
        </NavLink>

        <Button value={t('login_form:sign_in')} variant="primary" />
      </StyledForm>

      <PoweredByWrapper>
        <p>Powered By</p>
        <img src={AdvocatesProLogo} alt="AdvocatesPro" />
      </PoweredByWrapper>

      <BrowsersFooter />
    </>
  );
};

export default LoginForm;
