import styled, { css } from 'styled-components';
import { Colors } from 'styles/Constants';

export interface TrProps {
  activeRow?: boolean;
}

const Tr = styled.tr<TrProps>`
  ${({ activeRow }) =>
    activeRow &&
    css`
      td {
        background-color: ${Colors.primaryDarkBlue};
        color: #fff;

        &:before {
          background: ${Colors.primaryDarkBlue};
        }

        p {
          font-weight: 400;
          color: #fff;
        }

        &:first-child {
          &:before {
            background: transparent;
          }
        }
      }
    `}
`;

export default Tr;
