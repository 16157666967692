import styled from 'styled-components';
import { Colors } from 'styles/Constants';

const StyledDropdownSelect = styled.div`
  div.dropdown-filter-container {
    padding: 0 10px 10px;
  }

  .dropdown-filter-container {
    padding: 0 10px 10px;
  }

  div.dropdown-filter-content__control {
    width: 170px;
    border: none;
    border-radius: 0;
    background-color: transparent;
    border-bottom: 0.8px solid ${Colors.inactive};
    box-shadow: none;
    height: 30px;

    &:hover,
    &:focus-within {
      border-color: ${Colors.primaryDarkBlue};
      cursor: pointer;
    }
  }

  div.dropdown-filter-content__single-value {
    color: #3e66fe;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
  }

  div.dropdown-filter-content__value-container {
    padding: 0;
  }

  span.dropdown-filter-content__indicator-separator {
    display: none;
  }

  div.dropdown-filter-content__indicator {
    color: rgba(0, 0, 0, 0.54);
  }

  div.dropdown-filter-content__placeholder {
    padding-bottom: 15px;
    font-size: 15px;
    color: ${Colors.inactive};
  }

  div.dropdown-filter-content__menu {
    margin-top: 1px;
    width: 170px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    z-index: 5;
  }

  div.dropdown-filter-content__menu-list {
    padding-top: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  div.dropdown-filter-content__option {
    padding: 6px 8px;
    font-size: 14px;
  }

  div.dropdown-filter-content__option--is-focused {
    background: transparent;
  }

  div.dropdown-filter-content__option:hover,
  div.dropdown-filter-content__option--is-focused:hover,
  div.dropdown-filter-content__option--is-selected {
    background: rgba(0, 0, 0, 0.08);
    font-weight: 500;
    color: #3e66fe;
  }
`;

export default StyledDropdownSelect;
