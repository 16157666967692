import styled, { css } from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

export type StyledNumberProps = {
  size?: string;
  rounded?: boolean;
};

const StyledPlainNumber = styled.p<StyledNumberProps>`
  color: ${Colors.primaryDarkBlue};
  margin: 0 0 25px;
  font-weight: 700;
  line-height: 1;
  font-size: 1.5625rem;

  @media (min-width: ${Breakpoints.medium}) {
    font-size: 3.125rem;
  }

  ${({ rounded }) =>
    rounded &&
    css`
      color: ${Colors.normalWhite};
      background-color: #3e65fe;
      line-height: 100px;
      text-align: center;
      border-radius: 50%;
      height: 100px;
      width: 100px;
      margin: 0px;

      @media (min-width: 0px) {
        font-size: 35px;
      }
    `}

  ${({ size }) =>
    size &&
    css`
      @media (min-width: 0px) {
        font-size: ${size};
      }
    `}
`;

export default StyledPlainNumber;
