import React from 'react';
import styled from 'styled-components';
import StatusIcon, { PossibleStatus } from './StatusIcon';

const IconsContainer = styled.div`
  display: flex;

  ${StatusIcon} {
    position: relative;
    margin-left: -6px;
    margin-top: -6px;
  }
`;

export interface IconWithStatusProps {
  status: PossibleStatus;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
}

const IconWithStatus: React.FC<IconWithStatusProps> = props => {
  const { status, className, children, onClick } = props;

  return (
    <IconsContainer className={className} onClick={onClick}>
      {children}
      <StatusIcon variant={status} />
    </IconsContainer>
  );
};

export default IconWithStatus;
