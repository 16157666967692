import styled from 'styled-components';
import { Breakpoints } from 'styles/Constants';

const EmptyStateWrapper = styled.div`
  text-align: center;
  margin: 60px auto;
  width: 90%;

  @media (min-width: ${Breakpoints.medium}) {
    width: 60%;
  }

  h2 {
    font-weight: bold;
    font-size: 18px;
    display: block;
  }

  p {
    font-weight: 400;
    font-size: 15px;
    display: block;
  }

  img {
    margin: 25px auto;
    max-width: 250px;
    display: block;
  }
`;

export default EmptyStateWrapper;
