import React, { ReactNode } from 'react';

import {
  ProgressBarContainerProps,
  SimpleProgressBarProps,
  ProgressBarContainer,
  ProgressBarBox,
  SimpleProgressBar
} from './StyledProgressBar';

export type ProgressBarProps = SimpleProgressBarProps &
  ProgressBarContainerProps & {
    icon?: ReactNode;
    withNumber?: boolean;
  };

const ProgressBar = (props: ProgressBarProps) => {
  const { color, inactive, icon, percentage, withNumber } = props;

  return (
    <ProgressBarBox>
      {icon}

      <ProgressBarContainer inactive={inactive}>
        <SimpleProgressBar percentage={percentage} color={color} />
      </ProgressBarContainer>

      {withNumber && <span className="progress-bar-number">{percentage}%</span>}
    </ProgressBarBox>
  );
};

ProgressBar.defaultProps = {
  color: 'grey'
};

export default ProgressBar;
