import styled, { css } from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';

export interface TdProps {
  hideOnMobile?: boolean;
  collapsed?: boolean;
  active?: boolean;
}

const Td = styled.td<TdProps>`
  box-shadow: 0 1px 6px 0 rgba(79, 79, 79, 0.38);
  background-color: ${Colors.normalWhite};
  position: relative;
  padding: 10px 0px;
  font-weight: 500;
  font-size: 14px;
  color: ${Colors.secondaryNormalGrey};
  border: none;

  @media (min-width: ${Breakpoints.medium}) {
    padding: 15px 20px;
  }

  &:before {
    background: ${Colors.normalWhite};
    position: absolute;
    height: 100%;
    content: '';
    width: 5px;
    left: -5px;
    top: 0px;
  }

  &:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;

    &:before {
      background: transparent;
    }

    .id {
      padding-left: 5px;
    }
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  a {
    font-weight: 500;
    font-size: 14px;
    color: ${Colors.secondaryNormalGrey};

    &:hover {
      color: ${Colors.primaryDarkBlue};
    }
  }

  svg.rounded-icon {
    margin-top: 6px;
    box-shadow: none;

    @media only screen and (max-width: ${Breakpoints.medium}) {
      margin-top: 15px;
    }
  }

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      @media only screen and (max-width: ${Breakpoints.medium}) {
        display: none;
        margin: 0 auto;
        box-shadow: none;
      }
    `}

  ${({ collapsed }) =>
    collapsed &&
    css`
      @media only screen and (min-width: ${Breakpoints.medium}) {
        width: 250px;
        white-space: nowrap;
      }
    `}
`;

export default Td;
