import React from 'react';

import StyledPlainNumber, { StyledNumberProps } from './StyledPlainNumber';

export type NumberProps = StyledNumberProps & {
  value: string;
};

const PlainNumber: React.FC<NumberProps> = ({ value, children, ...props }) => (
  <StyledPlainNumber {...props}>
    {children}
    {value}
  </StyledPlainNumber>
);

export default PlainNumber;
