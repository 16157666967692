import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import NavBar, { NavBarProps } from './NavBar';

interface NavBarAnimations {
  blueNavbar?: boolean;
  navigationShadow?: boolean;
  customScroll: number;
  customWidth: number;
}

const onScroll = (args: NavBarAnimations) => {
  const { blueNavbar, navigationShadow, customScroll, customWidth } = args;
  const scroll = window.pageYOffset;
  const desktopLinks = document.getElementById('desktop_navigation');

  if (navigationShadow && desktopLinks) {
    if (scroll >= 100) {
      desktopLinks.classList.add('navigation-shadow');
    } else {
      desktopLinks.classList.remove('navigation-shadow');
    }
  }

  const navBar = document.getElementById('animated_navbar');

  if (blueNavbar && navBar) {
    const windowWidth = window.innerWidth;

    if (scroll >= customScroll && windowWidth <= customWidth) {
      navBar.classList.remove('transparent');
    } else {
      navBar.classList.add('transparent');
    }
  }
};

const TransparencyContext = React.createContext<() => void>(() => {});
export const useTransparency = () => React.useContext(TransparencyContext);

export interface AnimatedNavBarProps extends NavBarProps, NavBarAnimations {}

const AnimatedNavBar: React.FC<AnimatedNavBarProps> = props => {
  const {
    blueNavbar = false,
    navigationShadow = true,
    customScroll,
    customWidth,
    ...navBarProps
  } = props;

  const [withTransparency, setWithTransparency] = useState(blueNavbar);
  const toogleTransparency = blueNavbar
    ? () => setWithTransparency(!withTransparency)
    : () => {};

  const scrollListener = () =>
    onScroll({
      blueNavbar,
      navigationShadow,
      customScroll,
      customWidth
    });

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  });

  return (
    <TransparencyContext.Provider value={toogleTransparency}>
      <NavBar
        {...navBarProps}
        id="animated_navbar"
        className={cx('transparent', { 'with-transparency': withTransparency })}
      />
    </TransparencyContext.Provider>
  );
};

export default AnimatedNavBar;
