import Client from 'config/axios';
import camelcaseKeys from 'camelcase-keys';

export interface LoginData {
  email: string;
  password: string;
}

export const LoginRequest = async (loginData: LoginData) => {
  const { data, headers } = await Client.post('/auth/sign_in', loginData);
  return {
    data,
    headers: {
      'access-token': headers['access-token'],
      uid: headers['uid'],
      client: headers['client'],
      expiry: headers['expiry']
    }
  };
};

export const isLoggedIn = () => {
  const authHeaders = localStorage.getItem('ironmanAuth');

  if (!authHeaders) return false;

  const parsedHeaders = JSON.parse(authHeaders);
  const expiry = parsedHeaders['expiry'];

  if (!expiry) return false;

  const expiryDate = new Date(parseInt(expiry) * 1000);
  const now = new Date();

  return expiryDate > now;
};

export const Login = async (loginData: LoginData) => {
  try {
    const {
      data: { data: user },
      headers
    } = await LoginRequest(loginData);

    localStorage.setItem('ironmanAuth', JSON.stringify(headers));

    return { user: camelcaseKeys(user) };
  } catch ({ response }) {
    return { errors: response.data.errors };
  }
};
