import styled from 'styled-components';

const StyledNetworkParticipationBox = styled.div`
  h3 {
    width: auto;
    display: block;
    margin: 0 auto;
    color: #333333;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    font-family: inherit;
    margin-bottom: 15px;
  }
`;

export const StyledButtonCollection = styled.div`
  display: flex;
  justify-content: center;

  button {
    display: inline-block;
    text-align: center;
    margin-right: 5px;
  }
`;

export default StyledNetworkParticipationBox;
