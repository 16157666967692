import styled, { css } from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';

export interface StyledTableProps {
  fixedHead?: boolean;
  responsive?: boolean;
}

const Table = styled.table<StyledTableProps>`
  color: ${Colors.normalBlack};
  border-collapse: separate;
  border-spacing: 0 20px;
  margin-bottom: 10px;
  max-width: 100%;
  font-size: 1rem;
  width: 100%;

  ${({ fixedHead }) =>
    fixedHead &&
    css`
      /*This will work on every browser but Chrome Browser*/
      thead {
        background-color: ${Colors.secondaryLightBlue};
        position: -webkit-sticky;
        position: sticky;
        z-index: 1;
        top: 0;
      }

      /*This will work on every browser*/
      th {
        background-color: ${Colors.secondaryLightBlue};
        position: -webkit-sticky;
        padding: 0px 20px 10px;
        position: sticky;
        z-index: 1;
        top: 0;

        &:first-of-type,
        &:last-of-type {
          &:before {
            background-color: ${Colors.secondaryLightBlue};
            position: absolute;
            height: 100%;
            content: '';
            width: 10px;
            top: 0;
          }
        }

        &:first-of-type {
          &:before {
            left: -5px;
          }
        }

        &:last-of-type {
          &:before {
            right: -5px;
          }
        }
      }
    `}

  ${({ responsive }) =>
    responsive &&
    css`
      @media only screen and (max-width: ${Breakpoints.medium}) {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        position: relative;
        /* sort out borders */

        tr {
          border-bottom: none;
          padding: 0 10px;
        }

        th,
        td {
          vertical-align: top;
          margin: 0;
        }

        td {
          min-height: 1.25em;
          text-align: left;
          display: block;

          @media (max-width: ${Breakpoints.medium}) {
            text-align: center;
          }

          &:empty:before {
            content: '\00a0';
          }
        }

        th {
          text-align: center;
          padding: 10px 5px;
          display: block;

          span {
            min-height: 20px;
            min-width: 20px;
            height: 20px;
            width: 20px;
          }
        }

        thead {
          display: block;
          float: left;
          border: 0;
          border-right: 1px solid rgba(0, 0, 0, 0.12);

          tr {
            padding: 0 10px 0 0;
            display: block;

            &:before {
              content: '\00a0';
            }
          }
        }

        tbody {
          position: relative;
          white-space: nowrap;
          overflow-x: auto;
          display: block;
          width: auto;

          tr {
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    `}
`;

export default Table;
