import styled from 'styled-components';
import { Colors } from 'styles/Constants';

export const StyledForm = styled.form`
  margin-bottom: 25px;
  display: grid;

  a.forgot-password {
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 25px;
    text-align: right;
    font-weight: bold;
    font-size: 13px;
    color: #009ef5;
  }
`;

export const PoweredByWrapper = styled.div`
  grid-template-columns: 45% 55%;
  align-items: center;
  margin-bottom: 15px;
  display: grid;

  p {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    color: ${Colors.secondaryNormalGrey};
    margin: 0;
  }

  img {
    max-width: 100%;
  }
`;
