import React, { Suspense } from 'react';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Instagram as LoadingState } from 'react-content-loader';

import CampaignCard from '../CampaignCard';
import CampaignsListContainer from './Styled';
import EmptyStateBox from 'components/boxes/EmptyStateBox/EmptyStateBox';

import {
  CAMPAIGNS_LIST,
  CampaignsListData,
  CampaignsListVars
} from 'graphql/queries/employeeCampaigns';

export interface CampaignsListProps {
  employeeId: number;
  status: string;
  id: string;
}

const CampaignsList: React.FC<CampaignsListProps> = props => {
  const { status, employeeId, id } = props;

  const { loading, data, fetchMore } = useQuery<
    CampaignsListData,
    CampaignsListVars
  >(CAMPAIGNS_LIST, {
    variables: {
      employeeId,
      after: '',
      filters: {
        status: [status]
      }
    }
  });

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const {
    employee: {
      campaigns: {
        edges,
        pageInfo: { hasNextPage, endCursor }
      }
    }
  } = data as CampaignsListData;

  const fetchNextPage = () => {
    fetchMore({
      variables: {
        filters: {
          status: [status]
        },
        after: endCursor
      }
    });
  };

  const campaigns = edges.map(edge => edge.node);

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <CampaignsListContainer id={id} className="fancy-scrollbar">
        {campaigns.length > 0 ? (
          <InfiniteScroll
            scrollableTarget={id}
            dataLength={campaigns.length}
            hasMore={hasNextPage}
            next={fetchNextPage}
            loader={<h4>Loading...</h4>}
          >
            {campaigns.map((campaign, index) => (
              <CampaignCard {...campaign} key={index} />
            ))}
          </InfiniteScroll>
        ) : (
          <EmptyStateBox />
        )}
      </CampaignsListContainer>
    </Suspense>
  );
};

export default CampaignsList;
