import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Colors, Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';

export interface StyledVerticalBoxProps {
  disabled?: boolean;
}

const ellipsisText = (
  fontSize: string,
  lineHeight: number,
  linesToShow: number
): FlattenSimpleInterpolation => {
  return css`
    height: ${fontSize}*${lineHeight}*${linesToShow};
    -webkit-line-clamp: ${linesToShow};
    -webkit-box-orient: vertical;
    line-height: ${lineHeight};
    text-overflow: ellipsis;
    font-size: ${fontSize};
    overflow: hidden;

    display: block;
    display: -webkit-box;
  `;
};

export const ContentVerticalBox = styled.div<StyledVerticalBoxProps>`
  ${BaseBox};
  p {
    font-weight: 500;
    line-height: 1.5;
    font-size: 14px;
    margin: 30px 0 25px 0;
    ${ellipsisText('14px', 1.5, 2)};

    @media (min-width: ${Breakpoints.medium}) {
      ${ellipsisText('14px', 1.5, 3)};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &:before {
        content: '';
        border-radius: 10px;
        position: absolute;
        background: black;
        opacity: 0.2;
        height: 100%;
        width: 100%;
        z-index: 2;
      }
    `}
`;

export const HeaderVerticalBox = styled.div`
  position: relative;

  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
  }
`;

export const NetworkContent = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -25px;

  span {
    margin: 0 10px;
  }
`;

export const BodyVerticalBox = styled.div`
  padding: 20px;

  h2 {
    font-weight: bold;
    position: relative;
    font-size: 18px;
    min-height: 50px;

    @media (min-width: ${Breakpoints.medium}) {
      max-width: 80%;
    }

    &:before {
      background-color: ${Colors.normalBlack};
      position: absolute;
      bottom: -10px;
      content: '';
      height: 3px;
      width: 55px;
    }

    &.center-title {
      max-width: 100%;
      text-align: center;
      &:before {
        background-color: transparent;
      }
    }
  }

  p {
    margin-top: 30px;

    &.text-description {
      margin: 0;
      color: #7b7b7b;
      text-align: center;

      @media (max-width: ${Breakpoints.medium}) {
        display: none;
      }
    }
  }

  button {
    width: 90%;
    display: block;
    margin: 0 auto;
  }
`;
