import React from 'react';
import StyledFieldError from './Styled';
import { useTranslation } from 'react-i18next';

export interface FieldErrorProps {
  text: string;
}

const FieldError: React.FC<FieldErrorProps> = props => {
  const { text } = props;
  const { t } = useTranslation(['validations']);

  const translatedError = t(`validations:${text}`);

  return <StyledFieldError>{translatedError}</StyledFieldError>;
};

export default FieldError;
