import styled from 'styled-components';
import { Colors } from 'styles/Constants';

export const DropdownElement = styled.div`
  padding-bottom: 5px;
  align-items: center;
  display: flex;
`;

export const DropdownContentList = styled.ul`
  box-shadow: 0 1px 6px 0 rgba(79, 79, 79, 0.38);
  transition: all 0.5s ease-out 0s;
  background-color: #fff;
  position: absolute;
  border-radius: 4px;
  visibility: hidden;
  min-width: 120px;
  margin-top: 0;
  display: none;
  opacity: 0;
  z-index: 1;
`;

export const DropdownContentItem = styled.li`
  button {
    color: ${Colors.normalBlack};
    background: transparent;
    text-decoration: none;
    text-align: left;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    display: block;
    padding: 10px;
    outline: none;
    border: none;
    width: 100%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }

  div.confirmation-box {
    position: absolute;
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  width: fit-content;

  svg {
    cursor: pointer;
  }

  &:hover {
    ${DropdownContentList} {
      visibility: visible;
      display: block;
      opacity: 1;
    }
  }
`;
