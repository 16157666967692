import React from 'react';
import {
  StyledGeneralDataBox,
  StyledDataBoxItem
} from './StyledGeneralDataBox';
import PlainNumber from 'components/numbers/PlainNumber';

export interface DataBoxItemProps {
  icon: JSX.Element;
  value: string;
  text: string;
}

export interface GeneralDataBoxProps {
  items: Array<DataBoxItemProps>;
}

const DataBoxItem: React.FC<DataBoxItemProps> = props => {
  const { icon, value, text } = props;
  return (
    <StyledDataBoxItem>
      <PlainNumber value={value}>{icon}</PlainNumber>
      <h3>{text}</h3>
    </StyledDataBoxItem>
  );
};

const GeneralDataBox: React.FC<GeneralDataBoxProps> = props => {
  const { items } = props;
  return (
    <StyledGeneralDataBox>
      {items.map((item, index) => (
        <DataBoxItem key={index} {...item} />
      ))}
    </StyledGeneralDataBox>
  );
};

export default GeneralDataBox;
