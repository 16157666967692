import React from 'react';
import ReactSelect, {
  OptionTypeBase,
  ValueType,
  Props as SelectProps
} from 'react-select';
import StyledDropdownSelect from './Styled';

export interface DropdownSelectProps extends SelectProps<OptionTypeBase> {
  onSelect?: (value: OptionTypeBase) => void;
}

const DropdownSelect: React.FC<DropdownSelectProps> = props => {
  const { options, onSelect, ...otherProps } = props;

  const onChange = (value: ValueType<OptionTypeBase, false>) => {
    onSelect && onSelect(value as OptionTypeBase);
  };

  return (
    <StyledDropdownSelect>
      <ReactSelect
        {...otherProps}
        onChange={onChange}
        options={options}
        className="dropdown-filter-container"
        classNamePrefix="dropdown-filter-content"
      />
    </StyledDropdownSelect>
  );
};

export default DropdownSelect;
