import React, { useState } from 'react';
import { StyledTooltipWrapper, TooltipMessage } from './StyledTooltipWrapper';

export interface TooltipWrapperProps {
  message: string;
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = props => {
  const { message, children } = props;
  const [visible, setVisible] = useState(false);

  return (
    <StyledTooltipWrapper
      visible={visible}
      onMouseOver={() => setVisible(true)}
      onMouseOut={() => setVisible(false)}
    >
      {children}
      <TooltipMessage>{message}</TooltipMessage>
    </StyledTooltipWrapper>
  );
};

export default TooltipWrapper;
