import React, { useState } from 'react';

import cx from 'classnames';
import { StyledDescription, ToggleBox } from './StyledDescriptionBox';
import Icon from 'components/icons/Icon';
import { Colors } from 'styles/Constants';

export interface DescriptionBoxProps {
  title: string;
  textDescription?: string;
  toggle?: boolean;
}

const DescriptionBox: React.FC<DescriptionBoxProps> = props => {
  const { title, textDescription, toggle } = props;
  const [boxOpen, setBoxOpen] = useState(false);

  const changeBox = () => {
    setBoxOpen(!boxOpen);
  };

  return toggle ? (
    <ToggleBox>
      <div className="head-box" onClick={changeBox}>
        <h2 className={cx({ active: boxOpen })}>{title}</h2>
        <Icon
          name="arrow-down"
          color={Colors.normalBlack}
          className={cx({ 'rotate-icon': boxOpen })}
        />
      </div>
      {textDescription && (
        <p className={cx({ 'show-description': boxOpen })}>{textDescription}</p>
      )}
    </ToggleBox>
  ) : (
    <StyledDescription>
      <h2>{title}</h2>
      {textDescription && <p>{textDescription}</p>}
    </StyledDescription>
  );
};

DescriptionBox.defaultProps = {
  toggle: false
};

export default DescriptionBox;
