import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import CalendarWrapper from 'components/Calendar/StyledCalendar';
import StyledCalendarInput from 'components/Calendar/CalendarInput/StyledCalendarInput';

export const FiltersForm = styled.form`
  text-align: center;
  display: block;

  @media (min-width: ${Breakpoints.medium}) {
    align-items: center;
    margin: 20px 0;
    display: flex;
  }

  input {
    @media (min-width: ${Breakpoints.medium}) {
      margin-right: 10px;
      max-width: 300px;
    }
  }

  span.filter-by-label {
    margin: 20px auto;
    display: block;

    @media (min-width: ${Breakpoints.medium}) {
      display: inline;
      margin: 0 20px;
    }
  }

  div.dropdown-filter-content__control {
    @media (max-width: ${Breakpoints.medium}) {
      width: 100% !important;
    }
  }

  div.dropdown-filter-content__menu {
    @media (max-width: ${Breakpoints.medium}) {
      width: 95% !important;
    }
  }

  div.dropdown-filter-container {
    @media (max-width: ${Breakpoints.medium}) {
      margin-bottom: 15px;
    }
  }

  ${StyledCalendarInput} {
    border: none;
    border-bottom: 0.85px solid ${Colors.inactive};
    border-radius: 0;
    max-width: 300px;

    &:hover,
    &:focus-within {
      border-bottom: 0.85px solid ${Colors.primaryDarkBlue};
    }

    label {
      color: ${Colors.primaryDarkBlue};
      text-transform: uppercase;
    }
  }

  ${CalendarWrapper} {
    padding: 0 10px 12px;
  }
`;
