import React from 'react';
import styled from 'styled-components';

import RoundIcon, { StyledRoundSpan, RoundIconProps } from './RoundIcon';

import Icon from './Icon';

const StyledSquareSpan = styled(StyledRoundSpan)`
  border-radius: 6px;
`;

const SquareIcon = (props: RoundIconProps) => {
  const { background, ...otherProps } = props;

  return (
    <StyledSquareSpan background={background} {...otherProps}>
      <Icon {...otherProps} />
    </StyledSquareSpan>
  );
};

SquareIcon.defaultProps = RoundIcon.defaultProps;

export default SquareIcon;
