import React from 'react';
import Dropdown, { DropdownItemProps } from './Dropdown';
import IconWithStatus from 'components/icons/IconWithStatus';
import { PossibleStatus } from 'components/icons/StatusIcon';
import { Network } from 'types/appTypes';
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon
} from 'components/icons/Catalogue';

export interface SocialMediaDropdownProps {
  status: PossibleStatus;
  items: Array<DropdownItemProps>;
}

export interface UnknownSocialMediaProps extends SocialMediaDropdownProps {
  network: Network;
}

const FacebookDropdown = ({ status, items }: SocialMediaDropdownProps) => (
  <Dropdown items={items}>
    <IconWithStatus status={status}>
      <FacebookIcon shape="square" />
    </IconWithStatus>
  </Dropdown>
);

const InstagramDropdown = ({ status, items }: SocialMediaDropdownProps) => (
  <Dropdown items={items}>
    <IconWithStatus status={status}>
      <InstagramIcon shape="square" />
    </IconWithStatus>
  </Dropdown>
);

const TwitterDropdown = ({ status, items }: SocialMediaDropdownProps) => (
  <Dropdown items={items}>
    <IconWithStatus status={status}>
      <TwitterIcon shape="square" />
    </IconWithStatus>
  </Dropdown>
);

const LinkedinDropdown = ({ status, items }: SocialMediaDropdownProps) => (
  <Dropdown items={items}>
    <IconWithStatus status={status}>
      <LinkedinIcon shape="square" />
    </IconWithStatus>
  </Dropdown>
);

const SocialMediaDropdown = ({
  network,
  ...props
}: UnknownSocialMediaProps) => {
  switch (network) {
    case 'twitter':
      return <TwitterDropdown {...props} />;
    case 'facebook':
      return <FacebookDropdown {...props} />;
    case 'linkedin':
      return <LinkedinDropdown {...props} />;
    case 'instagram':
      return <InstagramDropdown {...props} />;
    default:
      return null;
  }
};

export {
  FacebookDropdown,
  InstagramDropdown,
  TwitterDropdown,
  LinkedinDropdown,
  SocialMediaDropdown
};
