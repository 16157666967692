import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';
import { StyledNetworkParticipationBox } from 'components/boxes/NetworkParticipationBox';

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  @media (min-width: ${Breakpoints.medium}) {
    grid-column-gap: 60px;
  }

  > div {
    grid-column: span 4;
  }

  svg[name='flag'] {
    stroke-width: 30px;
    stroke: ${Colors.primaryDarkBlue};
  }
`;

export const MobileWrapper = styled.div`
  ${BaseBox};

  text-align: left;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;

  > div {
    border-bottom: 2px solid #d8d8d8;
    margin-bottom: 20px;

    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;

      p,
      svg {
        margin-bottom: 0;
      }
    }
  }

  ${StyledNetworkParticipationBox} {
    padding-bottom: 15px;

    .button-collection {
      justify-content: flex-start;
    }

    h3 {
      text-align: left;
      width: 100%;
    }

    > * {
      margin-left: 0;
    }
  }
`;
