import { css } from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';

const BaseBox = css`
  box-shadow: 0 1px 6px 0 rgba(79, 79, 79, 0.38);
  transition: all 0.5s ease-out 0s;
  background: ${Colors.normalWhite};
  display: inline-block;
  margin-bottom: 20px;
  border-radius: 10px;
  position: relative;
  z-index: 2;

  h3 {
    font-size: 0.6875rem;
    font-weight: bold;
    margin: 0;

    width: 80%;
    display: block;
    margin: 0 auto;

    @media (min-width: ${Breakpoints.medium}) {
      font-size: 1rem;
      width: auto;
    }
  }

  h4 {
    font-weight: bold;
    min-height: 30px;
    font-size: 14px;

    @media (min-width: ${Breakpoints.medium}) {
      margin: 0px 10px;
    }
  }

  h3 + * {
    margin-top: 15px;
  }

  h3.small-description {
    @media (min-width: ${Breakpoints.medium}) {
      font-size: 0.625rem;
    }
  }

  // Hoverable Box
  &.hoverable {
    &:focus,
    &:hover {
      box-shadow: 0 4px 10px 0 ${Colors.primaryDarkBlue};
    }
  }

  &.no-shadow {
    box-shadow: none;
    grid-column-gap: 35px;
  }
`;

export default BaseBox;
