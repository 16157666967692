import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import {
  PositionColumn,
  TrophyColumn,
  Column,
  UserNameHeader,
  PointsHeader,
  Row,
  Table,
  Th
} from './StyledRankingTable';
import { TrophyIcon, WarningIcon } from 'components/icons/Catalogue';
import TooltipWrapper from 'components/TooltipWrapper/TooltipWrapper';

import EmployeeAvatar from 'images/avatars/employee.png';

export type Role = 'employee' | 'admin';

interface Employee {
  employeeId: number;
  position: number;
  points: number;
  photo: string;
  fullName: string;
  badPractices: boolean;
}

export type Employees = Array<Employee>;

export interface RankingTableProps {
  currentEmployeeId?: number;
  employees: Employees;
  role: Role;
}

const getBadPracticeMessage = (
  t: TFunction,
  isCurrentUser: boolean,
  role: Role
): string => {
  if (role === 'admin') return t('ranking_table:admin_bad_practices');
  if (isCurrentUser) return t('ranking_table:own_bad_practices');

  return t('ranking_table:employee_bad_practices');
};

const RankingTable: React.FC<RankingTableProps> = props => {
  const { employees, currentEmployeeId, role } = props;
  const { t } = useTranslation(['ranking_table']);

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = EmployeeAvatar;
  };

  return (
    <Table>
      <thead>
        <Row>
          <Th />
          <UserNameHeader>{t('ranking_table:employee')}</UserNameHeader>
          <PointsHeader>{t('ranking_table:points')}</PointsHeader>
          <Th />
        </Row>
      </thead>
      <tbody>
        {employees.map((employee, index) => {
          const isCurrent = currentEmployeeId === employee.employeeId;
          const message = getBadPracticeMessage(t, isCurrent, role);

          return (
            <Row key={index} activeRow={isCurrent}>
              <PositionColumn collapsed>
                <div className="id">
                  <p>{`${employee.position}.`}</p>
                  <img
                    src={employee.photo || EmployeeAvatar}
                    className="user-photo"
                    alt="User"
                    onError={evt => addDefaultPicture(evt)}
                  />
                  {employee.badPractices && (
                    <TooltipWrapper message={message}>
                      <WarningIcon />
                    </TooltipWrapper>
                  )}
                </div>
              </PositionColumn>

              <Column>{employee.fullName}</Column>
              <Column>{employee.points}</Column>

              <TrophyColumn>
                <TrophyIcon className="trophy" />
              </TrophyColumn>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};

export default RankingTable;
