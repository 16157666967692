import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ConfirmationOptionsWrapper,
  ConfirmationBoxWrapper,
  StyledConfirmationMessage,
  StyledConfirmationOption
} from './StyledConfirmationBox';

import Checkbox from 'components/inputs/checkbox/Checkbox';

// Confirmation Message
interface ConfirmationMessageProps {
  message: string;
}

const ConfirmationMessage: React.FC<ConfirmationMessageProps> = props => {
  const { message } = props;
  return <StyledConfirmationMessage>{message}</StyledConfirmationMessage>;
};

// Confirmation Option
interface ConfirmationOptionProps {
  text: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ConfirmationOption = ({ text, onClick }: ConfirmationOptionProps) => (
  <StyledConfirmationOption onClick={onClick}>{text}</StyledConfirmationOption>
);

// CONFIRMATION BOX

export interface Answer {
  question: string;
  value: boolean;
}

export type Answers = Array<Answer>;

export interface ConfirmationOptions {
  confirmationMessage: string;
  questions?: Array<string>;
}

export interface ConfirmationBoxProps extends ConfirmationOptions {
  isOpen: boolean;
  onConfirm: (answers?: Answers) => void;
  onCancel: (answers?: Answers) => void;
}

const buildAnswers = (questions: Array<string>): Answers =>
  questions.map(question => ({ question, value: false }));

const ConfirmationBox = (props: ConfirmationBoxProps) => {
  const {
    confirmationMessage,
    questions = [],
    onConfirm,
    onCancel,
    isOpen
  } = props;

  const initialValue: Answers = buildAnswers(questions);
  const [answers, setAnswers] = useState(initialValue);
  const { t } = useTranslation(['confirmation_box']);

  useEffect(() => {
    setAnswers(buildAnswers(questions));
  }, [questions]);

  const updateQuestion = (index: number) => {
    const newAnswers = [...answers];
    newAnswers[index].value = !newAnswers[index].value;
    setAnswers(newAnswers);
  };

  if (!isOpen) return null;

  return (
    <ConfirmationBoxWrapper className="confirmation-box">
      <ConfirmationMessage message={confirmationMessage} />

      {answers.map(({ question, value }, index) => (
        <Checkbox
          key={index}
          id={`question-${index}`}
          text={question}
          checked={value}
          onClick={() => updateQuestion(index)}
        />
      ))}

      <ConfirmationOptionsWrapper>
        <ConfirmationOption
          text={t('confirmation_box:yes')}
          onClick={() => onConfirm(answers)}
        />
        <ConfirmationOption
          text={t('confirmation_box:no')}
          onClick={() => onCancel(answers)}
        />
      </ConfirmationOptionsWrapper>
    </ConfirmationBoxWrapper>
  );
};

ConfirmationBox.defaultProps = {
  isOpen: true,
  questions: []
};

export default ConfirmationBox;
